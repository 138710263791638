const initState = {
  language: '',
  currency: '',
  general_terms_and_conditions_url: '',
  logo_url: '',
  browser_title: '',
  main_color: '',
}

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'settings/update':
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}

export default reducer
