import './Summary.scss'

import React, { useContext } from 'react'

import Block from './Block/Block'
import SettingsContext from '#context/settings'
import { Typography } from '@material-ui/core'
import camelCase from 'lodash/camelCase'
import money from '#utils/money'
import useLang from 'src/hooks/useLang'

const blocks = ['pickUp', 'handover', 'orderInfo']

const fields = {
    pickUp: ['address', 'contact', 'phone', 'companyName', 'note'],
    handover: ['address', 'contact', 'phone', 'companyName', 'note', 'cod'],
    orderInfo: ['size', 'service', 'paymentType', 'billingPartner'],
}

const Summary = props => {
    const { data: summaryData, handleEdit, description } = props

    const { settings } = useContext(SettingsContext)

    const lang = useLang(['order'])

    let actual_balance = settings.show_partner_actual_balance ? `(` + lang.order.actual_balance + `: ` + money(summaryData.partner.actual_balance, settings.currency) + `)` : ''

    const data = {
        pickUp: {
            address: summaryData.pick_up.address,
            contact: `${summaryData.pick_up.contact.first_name} ${summaryData.pick_up.contact.last_name}`,
            phone: summaryData.pick_up.contact.phone,
            companyName: summaryData.pick_up.company_name,
            note: summaryData.pick_up.note !== '' ? summaryData.pick_up.note : '-',
        },
        handover: {
            address: summaryData.recipients[0].address,
            contact: `${summaryData.recipients[0].contact.first_name} ${summaryData.recipients[0].contact.last_name}`,
            phone: summaryData.recipients[0].contact.phone,
            companyName: summaryData.recipients[0].company_name,
            note: summaryData.recipients[0].note,
            cod: summaryData.recipients[0].cash_on_delivery ? money(summaryData.recipients[0].cash_on_delivery, settings.currency) : '',
        },
        orderInfo: {
            size: summaryData.sizes[0].name,
            service: summaryData.service.name,
            paymentType: lang.order[camelCase(summaryData.payment_type)],
            billingPartner: `${summaryData.partner.billing_name} ${actual_balance}`
        },
    }


    return (
        <div className="Summary">
            <Typography variant="body1" className="mb-20" style={{ whiteSpace: "pre-line" }}>{description}</Typography>
            {blocks.map((block, index) => (
                <Block
                    key={index}
                    title={block}
                    fields={fields[block]}
                    data={data[block]}
                    onClickEdit={() => handleEdit(index)}
                />
            ))}

            <div className="price-block">
                <Typography variant="body1">
                    <strong>{lang.order.price}</strong> ({lang.order.gross.toLowerCase()})
                </Typography>
                <Typography>
                    <strong>{money(summaryData.gross_price, settings.currency)}</strong>
                </Typography>
            </div>
        </div>
    )
}

export default Summary
