import { gql } from '@apollo/client'

const GET_CO2_SAVINGS = gql`
  query CO2Savings {
    CO2Savings {
      this_month
      last_month
      all_time
    }
  }
`

export { GET_CO2_SAVINGS }
