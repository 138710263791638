import LocalizedStrings from 'react-localization'

let general = new LocalizedStrings({
  en: {
    back: 'Back',
    next: 'Next',
    cancel: 'Cancel',
    save: 'Save',
    ok: 'Ok',
  },
  hu: {
    back: 'Vissza',
    next: 'Következő',
    cancel: 'Mégsem',
    save: 'Mentés',
    ok: 'Ok',
  },
  de: {
    back: 'Zurück',
    next: 'Nächste',
    cancel: 'Stornieren',
    save: 'Speichern',
    ok: 'Ok',
  },
  it: {
    back: 'Indietro',
    next: 'Prossimo',
    cancel: 'Cancella',
    save: 'Salva',
    ok: 'Ok',
  },
  ro: {
    back: 'Înapoi',
    next: 'Înainte',
    cancel: 'Anulează',
    save: 'Salvează',
    ok: 'Ok',
  },
})

export default general
