import './Logo.scss'

import React, { useContext } from 'react'

import SettingsContext from '#context/settings'
import classNames from 'classnames'

const Logo = props => {
  const { className } = props

  const { settings } = useContext(SettingsContext)

  const classes = classNames('Logo', className)

  return (
    settings.logo_url && (
      <div className={classes}>
        <img src={settings.logo_url} alt="logo" />
      </div>
    )
  )
}

export default Logo
