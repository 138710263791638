import LocalizedStrings from 'react-localization'

let menu = new LocalizedStrings({
  en: {
    deliveries: 'Deliveries',
    settings: 'Settings',
  },
  hu: {
    deliveries: 'Kiszállítások',
    settings: 'Beállítások',
  },
  de: {
    deliveries: 'Lieferungen',
    settings: 'Einstellungen',
  },
  it: {
    deliveries: 'Spedizioni',
    settings: 'Impostazioni',
  },
  ro: {
    deliveries: 'Livrări',
    settings: 'Setări',
  },
})

export default menu
