import { gql } from '@apollo/client'

const VALIDATE_PICK_UP_BY_CLIENT = gql`
  mutation ValidatePickUpByClient($input: PickUpByClientInput!) {
    validatePickUpByClient(input: $input)
  }
`

const VALIDATE_RECIPIENT_BY_CLIENT = gql`
  mutation ValidateRecipientByClient($input: RecipientByClientInput!) {
    validateRecipientByClient(input: $input)
  }
`

const PREVIEW_ORDER_BY_CLIENT = gql`
  mutation PreviewOrderByClient($input: CreateOrderByClientInput!) {
    previewOrderByClient(input: $input) {
      sizes {
        size_id
        name
        width
        height
        depth
        status
        created_at
        updated_at
      }
      service {
        service_id
        name
        status
      }
      payment_type
      partner {
        partner_id
        name
        billing_name
        billing_street_name
        billing_zip_code
        billing_city
        payment_type
        tax_number
        actual_balance
        status
      }
      pick_up {
        address
        contact {
          contact_id
          first_name
          last_name
          phone
        }
        company_name
        note
      }
      recipients {
        address
        contact {
          contact_id
          first_name
          last_name
          phone
        }
        company_name
        note
        cash_on_delivery
      }
      net_price
      gross_price
    }
  }
`

const CREATE_ORDER_BY_CLIENT = gql`
  mutation CreateOrderByClient($input: CreateOrderByClientInput!) {
    createOrderByClient(input: $input) {
      order_id
    }
  }
`

export { VALIDATE_PICK_UP_BY_CLIENT, VALIDATE_RECIPIENT_BY_CLIENT, PREVIEW_ORDER_BY_CLIENT, CREATE_ORDER_BY_CLIENT }
