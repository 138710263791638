import axios from '#utils/axios'

export const login = ({ email, password }) => {
  const data = {
    grant_type: process.env.REACT_APP_API_GRANT_TYPE,
    client_id: process.env.REACT_APP_API_CLIENT_ID,
    client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
    // TODO[Ricsi]: atdolgozni, ha a ki lesz cserlve a username
    username: email,
    password: password,
  }

  return axios.post('/oauth/token', data)
}
