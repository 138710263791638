import { gql } from '@apollo/client'

const GET_SERVICES = gql`
  query GetServices {
    clientServices {
      service_id
      name
      sizes {
        size_id
        name
      }
      default
    }
  }
`

export { GET_SERVICES }
