import './SidebarFooter.scss'

import React, { useContext } from 'react'
import { Typography, useTheme } from '@material-ui/core'

import ClientContext from '#context/client'
import { useHistory } from 'react-router-dom'
import useLang from 'src/hooks/useLang'
import SettingsContext from '#context/settings'

const SidebarFooter = () => {
  const history = useHistory()
  const clientContext = useContext(ClientContext)
  const theme = useTheme()
  const lang = useLang(['sidebar'])

  const handleLogout = () => {
    localStorage.clear()
    clientContext.setClient({})
    history.push('/login')
  }

  return (
    <div className="SidebarFooter">
      <Typography className="mb-5" variant="caption" color="textSecondary">
        {lang.sidebar.loggedInAs}
      </Typography>
      <Typography className="email mb-15">{clientContext.client.email}</Typography>
      <Typography className="logout" style={{ color: theme.palette.primary.main }} onClick={handleLogout}>
        {lang.sidebar.logout}
      </Typography>
    </div>
  )
}

export default SidebarFooter
