import Content from '#components/Content/Content'
import MobileSidebar from '#components/MobileSidebar/MobileSidebar'
import Sidebar from '#components/Sidebar/Sidebar'
import React from 'react'

const AdminPage = props => {
  return (
    <div className="AdminPage">
      <Sidebar />
      <MobileSidebar />
      <Content />
    </div>
  )
}

export default AdminPage
