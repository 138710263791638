import { gql } from '@apollo/client'

const GET_CLIENT_PACKAGES = gql`
  query GetClientPackages($first: Int!, $page: Int!) {
    clientPackages(first: $first, page: $page, orderBy: [
      {
        field: "package_id",
        order: DESC
      }
    ]) {
      data {
        package_id
        formatted_package_id
        summary_with_tax
        cancellationReason {
            name
        }
        order {
          tracking_url
          created_at
          summary_with_tax
        }
        public_status
        pick_up {
          address {
            formatted_address
          }
        }
        recipients {
          address {
            formatted_address
          }
        }
      }
      paginatorInfo {
        currentPage
        lastPage
        total
      }
    }
  }
`

export { GET_CLIENT_PACKAGES }
