import LocalizedStrings from 'react-localization'

let homepage = new LocalizedStrings({
  en: {
    createAccountTitle: 'Create a Client Portal account',
    firstName: 'Firstname',
    lastName: 'Lastname',
    email: 'Email',
    password: 'Password',
    confirmPassword: 'Confirm password',
    accept: 'Accept {link}',
    privacyPolicy: 'Privacy Policy',
    createAccount: 'Create account',
    haveAnAccount: 'Already have an account?',
    signIn: 'Sign in',
    signInTitle: 'Sign in to Client Portal',
    forgotPassword: 'Forgot password?',
    passwordReset: 'Reset password',
    passwordResetDescription:
      'Enter your registered email address where we will send the password recovery information.',
    send: 'Send',
    passwordResetInstruction:
      'An email has been sent. Please check your email and follow the password recovery instructions.',
    createNewPassword: 'Create new password',
    confirm: 'Confirm',
    invalidEmailOrPassword: 'Invalid email address or password.',
    takenEmail: 'This email address has already been taken.',
    invalidRegistrationToken: 'The registration link has been expired or it has been already used.',
    missingRegistrationToken: 'The registration token is missing.',
    invalidNewPasswordToken: 'The new password link has been expired or it has been already used.',
    missingNewPasswordToken: 'The new password token is missing.',
    addressNotFitToService: 'The address is out of the service area.',
  },
  hu: {
    createAccountTitle: 'Ügyfélportál fiók létrehozása',
    firstName: 'Vezetéknév',
    lastName: 'Keresztnév',
    email: 'Email cím',
    password: 'Jelszó',
    confirmPassword: 'Jelszó megerősítése',
    accept: 'Elfogadom az {link}',
    privacyPolicy: 'Adatvédelni irányelveket',
    createAccount: 'Fiók létrehozása',
    haveAnAccount: 'Már van felhasználói fiókod?',
    signIn: 'Bejelentkezés',
    signInTitle: 'Bejelentkezés az Ügyfélportálra',
    forgotPassword: 'Elfelejtetted a jelszavad?',
    passwordReset: 'Új jelszó igénylése',
    passwordResetDescription:
      'Kérlek add meg az email címet, amivel regisztáltál, hogy el tudjuk küldeni az új jelszó igényléséhez szükséges információkat.',
    send: 'Küldés',
    passwordResetInstruction:
      'Az emailt elküldtük a megadott címre. Kérlek nézd meg és kövesd a benne található instrukciókat!',
    createNewPassword: 'Új jelszó létrehozása',
    confirm: 'Megerősítés',
    invalidEmailOrPassword: 'Hibás email cím, vagy jelszó.',
    takenEmail: 'Ez az email cím már használatban van. Kérlek használj egy másikat!',
    invalidRegistrationToken: 'A regisztrációs link már lejárt, vagy már korábban fel lett használva.',
    missingRegistrationToken: 'A regisztrációs token hiányzik.',
    invalidNewPasswordToken: 'Az új jelszó link már lejárt, vagy már korábban fel lett használva.',
    missingNewPasswordToken: 'Az új jelszó token hiányzik.',
    addressNotFitToService: 'A cím a szolgáltatás területén kívül esik.',
  },
  de: {
    createAccountTitle: 'Kundenkonto erstellen',
    firstName: 'Vorname',
    lastName: 'Nachname',
    email: 'E-Mail',
    password: 'Passwort',
    confirmPassword: 'Passwort bestätigen',
    accept: 'OK {link}',
    privacyPolicy: 'Datenschutz',
    createAccount: 'Konto erstellen',
    haveAnAccount: 'Haben Sie bereits ein Kundenkonto?',
    signIn: 'Login',
    signInTitle: 'Login zum Kundenportal',
    forgotPassword: 'Passwort vergessen?',
    passwordReset: 'Passwort zurücksetzen',
    passwordResetDescription: 'Geben Sie Ihre angegebene E-Mail-Adresse ein, an die wir die Informationen zur Wiederherstellung des Passworts senden werden.',
    send: 'Senden',
    passwordResetInstruction: 'Wir haben Ihnen eine E-Mail gesendet. Bitte prüfen Sie Ihr E-Mail-Postfach und folgen Sie den Anweisungen zur Passwortwiederherstellung.',
    createNewPassword: 'Neues Passwort erstellen',
    confirm: 'Bestätigen',
    invalidEmailOrPassword: 'Ungültige E-Mail-Adresse oder Passwort',
    takenEmail: 'Diese E-Mail-Adresse ist bereits vergeben.',
    invalidRegistrationToken: 'Der Registrierungslink ist abgelaufen oder wurde bereits verwendet.',
    missingRegistrationToken: 'Das Registrierungstoken fehlt.',
    invalidNewPasswordToken: 'Der Link für das neue Passwort ist abgelaufen oder wurde bereits verwendet.',
    missingNewPasswordToken: 'Das neue Passwort-Token fehlt.',
    addressNotFitToService: 'Die Adresse liegt außerhalb des Servicebereichs'
  },
  it: {
    createAccountTitle: 'Creare un profilo sul portale clienti',
    firstName: 'Nome',
    lastName: 'Cognome',
    email: 'E-mail',
    password: 'Password',
    confirmPassword: 'Conferma password',
    accept: 'Accetta {link}',
    privacyPolicy: 'Informativa sulla privacy',
    createAccount: 'Creare un profilo',
    haveAnAccount: 'Hai già un account?',
    signIn: 'Accedi',
    signInTitle: 'Accedi al portale clienti',
    forgotPassword: 'Password dimenticata?',
    passwordReset: 'Resetta la password',
    passwordResetDescription: 'Inserisci il tuo indirizzo email registrato a cui invieremo le informazioni per il recupero della password.',
    send: 'Spedisci',
    passwordResetInstruction: 'Una email è stata inviata. Controlla la tua email e segui le istruzioni per il recupero della password.',
    createNewPassword: 'Crea una nuova password',
    confirm: 'Conferma',
    invalidEmailOrPassword: 'Indirizzo email o password non validi.',
    takenEmail: 'Questo indirizzo email è già in uso.',
    invalidRegistrationToken: 'Il link di registrazione è scaduto o è già stato utilizzato.',
    missingRegistrationToken: 'Manca il token di registrazione.',
    invalidNewPasswordToken: 'Il collegamento della nuova password è scaduto o è già stato utilizzato.',
    missingNewPasswordToken: 'Il nuovo token della password è mancante.',
    addressNotFitToService: 'L\'indirizzo è fuori dall\'area operativa.',
  },
  ro: {
    createAccountTitle: 'Creează un cont Portal Client',
    firstName: 'Prenume',
    lastName: 'Nume',
    email: 'Email',
    password: 'Parolă',
    confirmPassword: 'Confirmă parola',
    accept: 'Acceptă {link}',
    privacyPolicy: 'Politica de Confidențialitate',
    createAccount: 'Creează cont',
    haveAnAccount: 'Ai deja un cont?',
    signIn: 'Loghează-te',
    signInTitle: 'Loghează-te în Portal Client',
    forgotPassword: 'Ai uitat parola?',
    passwordReset: 'Resetează parola',
    passwordResetDescription: 'Introdu adresa email înregistrată unde să îți trimitem informațiile pentru recuperarea parolei.',
    send: 'trimite',
    passwordResetInstruction: 'Emailul a fost trimis. Te rugăm verifică-ți emailul și urmează instrucțiunile de recuperare a parolei.',
    createNewPassword: 'Creează parolă nouă',
    confirm: 'Confirmă',
    invalidEmailOrPassword: 'Adresă de email sau parolă invalide.',
    takenEmail: 'Această adresă de email a fost deja folosită.',
    invalidRegistrationToken: 'Link-ul de înregistrare a expirat sau a fost deja folosit.',
    missingRegistrationToken: 'Token-ul pentru înregistrare lipsește.',
    invalidNewPasswordToken: 'Link-ul pentru parola nouă a expirat sau a fost deja folosit.',
    missingNewPasswordToken: 'Token-ul pentru parola nouă lipsește.',
    addressNotFitToService: 'Adresa este în afara ariei de serviciu'
  },
})

export default homepage
