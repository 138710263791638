import './SettingsPage.scss'

import React, { useContext, useState } from 'react'
import { Typography, useTheme, Checkbox, FormControlLabel } from '@material-ui/core'

import AddressBlock from '#components/AddressBlock/AddressBlock'
import ClientContext from '#context/client'
import LoadingButton from '#components/UI/LoadingButton/LoadingButton'
import PageHeader from '#components/PageHeader/PageHeader'
import { SAVE_CLIENT_PORTAL_ORDER_TEMPLATE } from '#graphql/mutators/client'
import Snackbar from '#components/Snackbar/Snackbar'
import { useFormik } from 'formik'
import useLang from 'src/hooks/useLang'
import { useMutation } from '@apollo/client'
import { useQuery } from '@apollo/client'
import { GET_SERVICES } from '#graphql/queries/service'
import OrderInfo from '#pages/OrderPage/Steps/OrderInfo/OrderInfo'



const SettingsPage = () => {
  const [buttonLoading, setButtonLoading] = useState(false)
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const theme = useTheme()
  const lang = useLang(['settings', 'general'])

  const {
    client: { default_client_portal_order_template: defaultOrderTemplate },
    setClient,
  } = useContext(ClientContext)

  const { data: servicesData } = useQuery(GET_SERVICES, {
    onCompleted() {
      setFieldValue('orderInfo.service', defaultOrderTemplate?.service?.service_id)
      setFieldValue('orderInfo.size', defaultOrderTemplate?.size?.size_id)
    }
  })

  const [saveClientPortalOrderTemplate] = useMutation(SAVE_CLIENT_PORTAL_ORDER_TEMPLATE, {
    errorPolicy: 'all',
  })

  const { errors, touched, getFieldProps, handleSubmit, setFieldValue, handleChange, values } = useFormik({
    initialValues: {
      pickUp: {
        zipCode: defaultOrderTemplate?.client_portal_delivery_point_templates[0]?.zip_code ?? '',
        city: defaultOrderTemplate?.client_portal_delivery_point_templates[0]?.city ?? '',
        address: defaultOrderTemplate?.client_portal_delivery_point_templates[0]?.address ?? '',
        floorDoor: defaultOrderTemplate?.client_portal_delivery_point_templates[0]?.floor_and_door ?? '',
        firstname: defaultOrderTemplate?.client_portal_delivery_point_templates[0]?.first_name ?? '',
        lastname: defaultOrderTemplate?.client_portal_delivery_point_templates[0]?.last_name ?? '',
        phone: defaultOrderTemplate?.client_portal_delivery_point_templates[0]?.phone_number ?? '',
        email: defaultOrderTemplate?.client_portal_delivery_point_templates[0]?.email ?? '',
        companyName: defaultOrderTemplate?.client_portal_delivery_point_templates[0]?.company_name ?? '',
        note: defaultOrderTemplate?.client_portal_delivery_point_templates[0]?.note ?? '',
        skipThisStep: defaultOrderTemplate?.client_portal_delivery_point_templates[0]?.skip_this_step ?? false,
      },
      handover: {
        zipCode: defaultOrderTemplate?.client_portal_delivery_point_templates[1]?.zip_code ?? '',
        city: defaultOrderTemplate?.client_portal_delivery_point_templates[1]?.city ?? '',
        address: defaultOrderTemplate?.client_portal_delivery_point_templates[1]?.address ?? '',
        floorDoor: defaultOrderTemplate?.client_portal_delivery_point_templates[1]?.floor_and_door ?? '',
        firstname: defaultOrderTemplate?.client_portal_delivery_point_templates[1]?.first_name ?? '',
        lastname: defaultOrderTemplate?.client_portal_delivery_point_templates[1]?.last_name ?? '',
        phone: defaultOrderTemplate?.client_portal_delivery_point_templates[1]?.phone_number ?? '',
        email: defaultOrderTemplate?.client_portal_delivery_point_templates[1]?.email ?? '',
        companyName: defaultOrderTemplate?.client_portal_delivery_point_templates[1]?.company_name ?? '',
        note: defaultOrderTemplate?.client_portal_delivery_point_templates[1]?.note ?? '',
        cod: 0,
      },
      orderInfo: {
        service: defaultOrderTemplate?.service?.service_id,
        size: defaultOrderTemplate?.size?.size_id
      }
    },
    onSubmit: values => {
      save(values)
    },
  })

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false)
  }

  const save = values => {
    setButtonLoading(true)

    saveClientPortalOrderTemplate({
      variables: {
        input: {
          service_id: values.orderInfo.service,
          size_id: values.orderInfo.size,
          default: true,
          client_portal_delivery_point_templates: [
            {
              zip_code: values.pickUp.zipCode,
              city: values.pickUp.city,
              address: values.pickUp.address,
              floor_and_door: values.pickUp.floorDoor,
              first_name: values.pickUp.firstname,
              last_name: values.pickUp.lastname,
              phone_number: values.pickUp.phone,
              email: values.pickUp.email,
              company_name: values.pickUp.companyName,
              note: values.pickUp.note,
              skip_this_step: values.pickUp.skipThisStep,
              order: 1,
              event: 'pick_up'
            },
            {
              zip_code: values.handover.zipCode,
              city: values.handover.city,
              address: values.handover.address,
              floor_and_door: values.handover.floorDoor,
              first_name: values.handover.firstname,
              last_name: values.handover.lastname,
              phone_number: values.handover.phone,
              email: values.handover.email,
              company_name: values.handover.companyName,
              note: values.handover.note,
              skip_this_step: false,
              order: 2,
              event: 'handover'
            }
          ],
        },
      },
    }).then(response => {
      if (response.errors) {
        console.log('error: ', response.errors)
      } else {
        setClient(prevState => ({
          ...prevState,
          default_client_portal_order_template: {
            ...response.data.saveClientPortalOrderTemplate,
          },
        }))

        setButtonLoading(false)
        setOpenSnackbar(true)
      }
    })
  }

  return (
    <div className="SettingsPage page">
      <PageHeader
        title={lang.settings.settings} />

      <div className="settings-container">
        {/* Egyelőre kikommenteltem PC nézetben is ezt a menüsávot, amíg nem kerül be több almenü, teljesen felesleges. */}
        {/* <div className="settings-menu">
          <Typography className="settings-menu-item active" style={{ color: theme.palette.primary.main }}>
            {lang.settings.defaultPickUp}
          </Typography>
        </div> */}

        <div className="settings-content mb-40">
          <form onSubmit={handleSubmit}>
            <Typography className="mb-20" variant="h6">
              {lang.settings.defaultPickUp}
            </Typography>

            <AddressBlock section="pickUp" touched={touched} errors={errors} getFieldProps={getFieldProps} requiredFields={[]} />

            <FormControlLabel
              className="my-15"
              label={lang.settings.skipThisStep}
              control={<Checkbox color="primary" checked={getFieldProps('pickUp.skipThisStep')['value']} />}
              {...getFieldProps('pickUp.skipThisStep')}
            />

            <Typography className="mt-20 mb-20" variant="h6">
              {lang.settings.defaultHandover}
            </Typography>

            <AddressBlock section="handover" touched={touched} errors={errors} getFieldProps={getFieldProps} requiredFields={[]} />
            {/* <FormControlLabel
              className="my-15"
              label={lang.settings.skipThisStep}
              control={<Checkbox color="primary" checked={getFieldProps('handover.skipThisStep')['value']} />}
              {...getFieldProps('handover.skipThisStep')}
            /> */}

            <Typography className="mt-20 mb-20" variant="h6">
              {lang.settings.defaultOrderInfo}
            </Typography>

            {servicesData?.clientServices ? (
              <OrderInfo
                section="orderInfo"
                touched={touched?.orderInfo ?? {}}
                errors={errors?.orderInfo ?? {}}
                services={servicesData?.clientServices ?? null}
                selectedSize={defaultOrderTemplate?.size}
                getFieldProps={getFieldProps}
                handleChange={handleChange}
                values={values?.orderInfo}
              />) : ''}

            <div className="text-right mt-20">
              <LoadingButton className="save-button" loading={buttonLoading} type="submit">
                {lang.general.save}
              </LoadingButton>
            </div>

            <Snackbar
              open={openSnackbar}
              message={lang.settings.successfulSaveMessage}
              handleClose={handleCloseSnackbar}
              autoClose={2000}
            />
          </form>
        </div>
      </div>
    </div>
  )
}

export default SettingsPage
