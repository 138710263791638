import { gql } from '@apollo/client'

const GET_CLIENT_PORTAL_SETTINGS = gql`
  query GetClientPortalSettings {
    client_portal_settings {
      language
      currency
      general_terms_and_conditions_url
      logo_url
      browser_title
      main_color
      show_partner_actual_balance
      show_co2_saving
      zip_code_input
      pick_up_description
      handover_description
      order_info_description
      summary_description
    }
  }
`

export { GET_CLIENT_PORTAL_SETTINGS }
