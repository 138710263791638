import './MobileSidebar.scss'

import { IconButton, SwipeableDrawer } from '@material-ui/core'
import React, { useContext, useState } from 'react'
import SettingsContext from '#context/settings'

import Menu from '#components/Menu/Menu'
import MenuIcon from '@material-ui/icons/Menu'
import SidebarFooter from '#components/Sidebar/SidebarFooter/SidebarFooter'
import SidebarHeader from '#components/Sidebar/SidebarHeader/SidebarHeader'
import CO2Savings from '#components/CO2Savings/CO2Savings'
import ActualBalance from '#components/ActualBalance/ActualBalance'

const MobileSidebar = props => {
  const [openDrawer, setOpenDrawer] = useState(false)

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer)
  }

  const { settings } = useContext(SettingsContext)

  let CO2SavingsComponent = ''
  let ActualBalanceComponent = ''

  if (settings.show_co2_saving) {
    CO2SavingsComponent = <CO2Savings />
  }

  if (settings.show_partner_actual_balance) {
    ActualBalanceComponent = <ActualBalance />
  }

  return (
    <div className="MobileSidebar">
      <IconButton className="menu-icon" onClick={toggleDrawer}>
        <MenuIcon />
      </IconButton>

      <SwipeableDrawer
        open={openDrawer}
        onOpen={() => setOpenDrawer(true)}
        onClose={() => setOpenDrawer(false)}
        onClick={() => setOpenDrawer(false)}
      >
        <div className="mobile-sidebar-content">
          <SidebarHeader />
          <Menu />
          {CO2SavingsComponent}
          {ActualBalanceComponent}
          <SidebarFooter />
        </div>
      </SwipeableDrawer>
    </div>
  )
}

export default MobileSidebar
