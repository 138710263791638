const currencies = {
  eur: {
    symbol: '€',
    decimalPlaces: 2
  },
  huf: {
    symbol: 'Ft',
    decimalPlaces: 0
  },
  usd: {
    symbol: '$',
    decimalPlaces: 2
  },
  lei: {
    symbol: 'lei',
    decimalPlaces: 2
  },
  dkk: {
    symbol: 'kr',
    decimalPlaces: 2
  },
  ticket: {
    symbol: 'tag',
    decimalPlaces: 0
  }
}

const money = (value, currency) => {
  const selectedCurrency = currencies[currency];
  return `${value.toFixed(selectedCurrency.decimalPlaces)} ${selectedCurrency.symbol}`
}

export default money
