import './SidebarHeader.scss'

import { Button, Typography } from '@material-ui/core'

import Logo from '#components/Logo/Logo'
import React from 'react'
import { useHistory } from 'react-router-dom'
import useLang from 'src/hooks/useLang'

const SidebarHeader = () => {
  const history = useHistory()
  const lang = useLang(['sidebar'])

  const handleClickOrderButton = event => {
    history.push('/order')
  }

  return (
    <div className="SidebarHeader">
      <Logo className="mb-35" />

      <Typography className="mb-20" variant="h6">
        {lang.sidebar.clientPortal}
      </Typography>

      <Button fullWidth variant="contained" color="primary" onClick={handleClickOrderButton}>
        {lang.sidebar.order}
      </Button>
    </div>
  )
}

export default SidebarHeader
