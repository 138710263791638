import { Button, Container, Link, Paper, TextField, Typography } from '@material-ui/core'
import React, { Fragment, useState } from 'react'

import { CREATE_PASSWORD_RESET_TOKEN_BY_CLIENT } from '#graphql/mutators/password'
import EmailIcon from '@material-ui/icons/Email'
import Logo from '#components/Logo/Logo'
import { useFormik } from 'formik'
import useLang from 'src/hooks/useLang'
import { useMutation } from '@apollo/client'
import useYup from '#hooks/useYup'

const PasswordResetPage = props => {
  const { history } = props

  const [sent, setSent] = useState(false)

  const lang = useLang(['validation', 'homepage', 'general'])

  const [createPasswordResetTokenByClient] = useMutation(CREATE_PASSWORD_RESET_TOKEN_BY_CLIENT, {
    errorPolicy: 'all',
  })

  const yup = useYup()

  const validationSchema = yup.object({
    email: yup.string().required().email(),
  })

  const { touched, errors, handleSubmit, getFieldProps, setErrors } = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: values => {
      save(values)
    },
  })

  const save = values => {
    createPasswordResetTokenByClient({
      variables: {
        input: values,
      },
    }).then(response => {
      if (response.errors) {
        handleErrors(response)
      } else {
        setSent(true)
      }
    })
  }

  const handleErrors = response => {
    Object.values(response?.errors[0]?.extensions?.validation).forEach(validations => {
      if (validations.includes('client_not_exists')) {
        setErrors({
          email: lang.validation.emailNotExists,
        })
      }
    })
  }

  const handleClickLink = event => {
    event.preventDefault()

    history.push('/login')
  }

  return (
    <Container className="PasswordResetPage page" maxWidth="md">
      <Paper className="auth-block" elevation={3} square>
        <Logo className="mb-30" />

        <Typography className="mb-30" variant="h5">
          {lang.homepage.passwordReset}
        </Typography>

        {!sent ? (
          <Fragment>
            <Typography className="mb-20" variant="body1">
              {lang.homepage.passwordResetDescription}
            </Typography>

            <form onSubmit={handleSubmit}>
              <TextField
                className="mb-20"
                label={lang.homepage.email}
                fullWidth
                error={touched.email && !!errors.email}
                helperText={touched.email && errors.email}
                {...getFieldProps('email')}
              />

              <Button className="mb-30" fullWidth variant="contained" color="primary" type="submit">
                {lang.homepage.send}
              </Button>
            </form>

            <Typography variant="body1">
              <span className="mr-5">{lang.homepage.haveAnAccount}</span>
              <Link onClick={handleClickLink}>{lang.homepage.signIn}</Link>
            </Typography>
          </Fragment>
        ) : (
            <Fragment>
              <div className="flex">
                <EmailIcon className="mr-15" color="disabled" fontSize="large" />
                <Typography variant="body1">{lang.homepage.passwordResetInstruction}</Typography>
              </div>

              <Button
                className="mt-20"
                fullWidth
                variant="contained"
                color="primary"
                onClick={() => history.push('/login')}
              >
                {lang.general.ok}
              </Button>
            </Fragment>
          )}
      </Paper>
    </Container>
  )
}

export default PasswordResetPage
