import { gql } from '@apollo/client'

const GET_CLIENT = gql`
  query GetClient {
    client {
      client_id
      first_name
      last_name
      email
      partners {
        actual_balance
      }
      default_client_portal_order_template {
        client_portal_order_template_id
        service {
          service_id
        }
        size {
          size_id
        }
        default
        client_portal_delivery_point_templates {
          zip_code
          event
          city
          address
          floor_and_door
          first_name
          last_name
          phone_country_prefix
          phone_number
          email
          company_name
          note
          skip_this_step
          cash_on_delivery
          order
        }
      }
    }
  }
`

export { GET_CLIENT }
