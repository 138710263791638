import { ApolloProvider } from '@apollo/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import React from 'react'
import ReactDOM from 'react-dom'
import apollo from '#utils/apollo'
import store from './store/store'

const app = (
  <BrowserRouter basename="/">
    <Provider store={store}>
      <ApolloProvider client={apollo}>
        <App />
      </ApolloProvider>
    </Provider>
  </BrowserRouter>
)

ReactDOM.render(app, document.getElementById('root'))
