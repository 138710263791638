import LocalizedStrings from 'react-localization'

let deliveries = new LocalizedStrings({
    en: {
        deliveries: 'Deliveries',
        track: 'Track',
        packageId: 'Package ID',
        orderTime: 'Order time',
        status: 'Status',
        pickup: 'Pickup',
        handover: 'Handover',
        ordered: 'Ordered',
        picked_up: 'Picked up',
        delivered: 'Delivered',
        delivery_failed: 'Delivery failed',
        cancelled: 'Cancelled',
        unknown: 'Unknown',
        printPackageIdStickers: 'Print package Id stickers',
        price: 'Price',
        client_is_not_available: 'Client is not available',
        wrong_address: 'Wrong address',
        package_rejected: 'Package rejected',
        entrance_is_closed: 'Entrance is closed',
        payment_problem: 'Payment problem',
        cancelled_by_client: 'Cancelled by client',
        other_problem: 'Other problem',
    },
    hu: {
        deliveries: 'Kiszállítások',
        track: 'Csomagkövetés',
        packageId: 'Csomag azonosító',
        orderTime: 'Rendelési idő',
        status: 'Státusz',
        pickup: 'Felvétel',
        handover: 'Leadás',
        ordered: 'Megrendelve',
        picked_up: 'Felvéve',
        delivered: 'Kiszállítva',
        delivery_failed: 'Sikertelen kiszállítás',
        cancelled: 'Lemondva',
        unknown: 'Ismeretlen',
        printPackageIdStickers: 'Csomag ID matricák nyomtatása',
        price: 'Ár',
        client_is_not_available: 'Ügyfél nem elérhető',
        wrong_address: 'Rossz cím',
        package_rejected: 'Csomag elutasítva',
        entrance_is_closed: 'Bejárat zárva',
        payment_problem: 'Fizetési probléma',
        cancelled_by_client: 'Megrendelő lemondta',
        other_problem: 'Egyéb probléma',

    },
    de: {
        deliveries: 'Lieferungen',
        track: 'Tracking',
        packageId: 'Paket-ID',
        orderTime: 'Auftragszeit',
        status: 'Status',
        pickup: 'Abholung',
        handover: 'Übergabe',
        ordered: 'Beauftragt',
        picked_up: 'Abgeholt',
        delivered: 'Zugestellt',
        delivery_failed: 'Zustellung fehlgeschlagen',
        cancelled: 'Storniert',
        unknown: 'Unbekannt',
        printPackageIdStickers: 'Drucken Sie die Paket-ID-Aufkleber',
        price: 'Preis',

    },
    it: {
        deliveries: 'Spedizioni',
        track: 'Tracciamento',
        packageId: 'ID pacco',
        orderTime: 'Orario ordine',
        status: 'Status',
        pickup: 'Ritiro',
        handover: 'Consegna',
        ordered: 'Ordinato',
        picked_up: 'Ritirato',
        delivered: 'Consegnato',
        cancelled: 'Cancellato',
        delivery_failed: 'Consegna fallita',
        unknown: 'Sconosciuto',
        printPackageIdStickers: 'Stampa adesivi ID pacchetto',
        price: 'Prezzo',
    },
    ro: {
        deliveries: 'Livrări',
        track: 'Urmărește',
        packageId: 'ID Pachet',
        orderTime: 'Ora Comenzii',
        status: 'Status',
        pickup: 'Ridicare',
        handover: 'Predare',
        ordered: 'Comandat',
        picked_up: 'Ridicat',
        delivered: 'Livrat',
        delivery_failed: 'Delivery failed',
        cancelled: 'Anulat',
        unknown: 'Necunoscu',
        printPackageIdStickers: 'Imprimați autocolante',
        price: 'Preț',
    },
})

export default deliveries
