import './MenuItem.scss'

import { Typography, useTheme } from '@material-ui/core'

import { NavLink } from 'react-router-dom'
import React from 'react'

const MenuItem = props => {
  const { title, route, icon } = props

  const theme = useTheme()

  return (
    <NavLink className="MenuItem" to={route} activeStyle={{ color: theme.palette.primary.main }}>
      <div className="icon">{icon}</div>
      <Typography variant="body1">{title}</Typography>
    </NavLink>
  )
}

export default MenuItem
