import './Block.scss'

import { IconButton, Typography } from '@material-ui/core'

import EditIcon from '@material-ui/icons/Edit'
import React from 'react'
import { isEmpty, isString } from 'lodash'
import useLang from 'src/hooks/useLang'

const Block = props => {
  const { title, fields, data, onClickEdit } = props

  const lang = useLang(['order'])

  return (
    <div className="Block">
      <Typography className="title mb-20">
        <strong>{lang.order[title]}</strong>
      </Typography>

      <IconButton className="edit-icon" onClick={onClickEdit}>
        <EditIcon />
      </IconButton>

      {fields.map((field, index) => (
        <div key={index} className="row">
          <Typography>{lang.order[field]}</Typography>
          <Typography>{(isString(data[field]) && isEmpty(data[field].trim())) || isEmpty(data[field]) ? '-' : data[field]}</Typography>
        </div>
      ))}
    </div>
  )
}

export default Block
