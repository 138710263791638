import { SvgIcon } from '@material-ui/core'
import React from 'react'

const PackagesIcon = props => (
  <SvgIcon {...props}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
    >
      <g id="_013-boxes-1" data-name="013-boxes-1" transform="translate(0 0)">
        <path
          id="Path_191"
          data-name="Path 191"
          d="M133.656.7a.7.7,0,0,0-.7-.7h-3.516V3.984a.7.7,0,0,1-.7.7h-2.812a.7.7,0,0,1-.7-.7V0H121.7a.7.7,0,0,0-.7.7V9.891h12.656Z"
          transform="translate(-115.328 0)"
          // fill="#666"
        />
        <path
          id="Path_192"
          data-name="Path 192"
          d="M241,0h1.406V3.281H241Z"
          transform="translate(-229.703 0)"
          // fill="#666"
        />
        <path
          id="Path_193"
          data-name="Path 193"
          d="M7.734,245.544a.7.7,0,0,1-.7.7H4.219a.7.7,0,0,1-.7-.7V241.56H.7a.7.7,0,0,0-.7.7v11.271a.7.7,0,0,0,.7.7H11.3V241.56H7.734Z"
          transform="translate(0 -230.237)"
          // fill="#666"
        />
        <path
          id="Path_194"
          data-name="Path 194"
          d="M105,241.563h1.406v3.281H105Z"
          transform="translate(-100.078 -230.24)"
          // fill="#666"
        />
        <path
          id="Path_195"
          data-name="Path 195"
          d="M281.594,241.56h-2.812v3.984a.7.7,0,0,1-.7.7h-2.812a.7.7,0,0,1-.7-.7V241.56H271v12.677h10.594a.7.7,0,0,0,.7-.7V242.263A.7.7,0,0,0,281.594,241.56Z"
          transform="translate(-258.297 -230.237)"
          // fill="#666"
        />
        <path
          id="Path_196"
          data-name="Path 196"
          d="M377,241.563h1.406v3.281H377Z"
          transform="translate(-359.328 -230.24)"
          // fill="#666"
        />
      </g>
    </svg>
  </SvgIcon>
)

export default PackagesIcon
