import './CO2Savings.scss'

import React, { useState } from 'react'
import { Typography, useTheme, Box } from '@material-ui/core'
import EcoIcon from '@material-ui/icons/Eco';
import { GET_CO2_SAVINGS } from '#graphql/queries/co2-savings'
import { useQuery } from '@apollo/client'
import useLang from 'src/hooks/useLang'

const CO2Savings = () => {
  const [CO2Savings, setCO2Savings] = useState({
    'thisMonth': 0,
    'lastMonth': 0,
    'allTime': 0,
  })

  const lang = useLang(['co2'])

  const theme = useTheme()

  useQuery(GET_CO2_SAVINGS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setCO2Savings({
        'thisMonth': data.CO2Savings.this_month.toFixed(1),
        'lastMonth': data.CO2Savings.last_month.toFixed(1),
        'allTime': data.CO2Savings.all_time.toFixed(1),
      })
    }
  })

  return (
    <div className="CO2Savings">
      <Box className="mb-5 flex" alignItems="center">
        <EcoIcon style={{ color: theme.palette.success.main }} className="mr-5" />
        <Typography variant="subtitle2" style={{ color: theme.palette.success.main }}>
          {lang.co2.co2Savings}
        </Typography>
      </Box>
      <Typography variant="caption" color="textSecondary">
        {lang.co2.thisMonth}: {CO2Savings.thisMonth} kg
      </Typography>
      <br />
      <Typography variant="caption" color="textSecondary">
        {lang.co2.lastMonth}: {CO2Savings.lastMonth} kg
      </Typography>
      <br />
      <Typography variant="caption" color="textSecondary">
        {lang.co2.allTime} {CO2Savings.allTime} kg
      </Typography>
    </div>
  )
}

export default CO2Savings