import React, { useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'

import ClientContext from '#context/client'

const PrivateRoute = ({ component: Component, ...rest }) => {
  const clientContext = useContext(ClientContext)

  return (
    <Route
      {...rest}
      render={props =>
        clientContext.client.client_id ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  )
}

export default PrivateRoute
