import * as yup from 'yup'

import lang from '#constants/lang'
import store from '../store/store'

const useYup = () => {
  lang.validation.setLanguage(store.getState().settings.language)

  yup.setLocale({
    mixed: {
      required: lang.validation.required,
      oneOf: lang.validation.passwordsNotMatch,
      notType: ({ type }) => {
        switch (type) {
          case 'number':
            return lang.validation.number
          default:
            return lang.validation.invalidType
        }
      },
    },
    string: {
      email: lang.validation.email,
      min: ({ min }) => lang.validation.formatString(lang.validation.min, min),
    },
  })

  return yup
}

export default useYup
