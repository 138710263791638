import AXIOS from 'axios'

const axios = AXIOS.create({
  baseURL: process.env.REACT_APP_API_URL,
})

axios.CancelToken = AXIOS.CancelToken
axios.isCancel = AXIOS.isCancel

// axios.interceptors.request.use(config => {

// })

// TODO[Ricsi]: CancelToken

export default axios
