import LocalizedStrings from 'react-localization'

let co2 = new LocalizedStrings({
  en: {
    co2Savings: 'CO2 savings',
    thisMonth: 'This month',
    lastMonth: 'Last month',
    allTime: 'All-time',
  },
  hu: {
    co2Savings: 'CO2 megtakarítás',
    thisMonth: 'Aktuális hónap',
    lastMonth: 'Előző hónap',
    allTime: 'Összesen',
  },
  de: {
    co2Savings: 'CO2-Einsparungen',
    thisMonth: 'Dieser Monat',
    lastMonth: 'Letzter Monat',
    allTime: 'Alle Zeiten',
  },
  it: {
    co2Savings: 'CO2 risparmiata',
    thisMonth: 'Questo mese',
    lastMonth: 'Lo scorso mese',
    allTime: 'Sempre',
  },
  ro: {
    co2Savings: 'Emisii CO2 economisite',
    thisMonth: 'Luna asta',
    lastMonth: 'Luna trecuta',
    allTime: 'In total',
  },
})

export default co2
