import LocalizedStrings from 'react-localization'

let order = new LocalizedStrings({
  en: {
    order: 'Order delivery',
    pickUp: 'Pick up',
    handover: 'Handover',
    orderInfo: 'Order info',
    summary: 'Summary',
    setEarliestPickUpTime: 'Set custom earliest pick up time',
    dateTime: 'Date / time',
    cod: 'Cash on delivery',
    size: 'Size',
    address: 'Address',
    contact: 'Contact',
    phone: 'Phone',
    companyName: 'Company name',
    note: 'Note',
    time: 'Time',
    service: 'Service',
    paymentType: 'Payment type',
    billingPartner: 'Billing partner',
    price: 'Price',
    gross: 'Gross',
    successfulOrderTitle: 'Successful order',
    successfulOrderContent: 'Thank you for choosing our delivery service!',
    newOrder: 'New order',
    cash: 'Cash',
    wireTransfer: 'Wire transfer',
    ticket: 'Ticket',
    actual_balance: 'Actual balance'
  },
  hu: {
    order: 'Megrendelés',
    pickUp: 'Felvétel',
    handover: 'Leadás',
    orderInfo: 'Rendelési információk',
    summary: 'Összegzés',
    setEarliestPickUpTime: 'Legkorábbi felvételi idő beállítása',
    dateTime: 'Dátum/ idő',
    cod: 'Utánvét összege',
    size: 'Méret',
    address: 'Cím',
    contact: 'Kontakt',
    phone: 'Telefonszám',
    companyName: 'Cégnév',
    note: 'Megjegyzés',
    time: 'Idő',
    service: 'Szolgáltatás',
    paymentType: 'Fizetés típusa',
    billingPartner: 'Számlázási partner',
    price: 'Ár',
    gross: 'Bruttó',
    successfulOrderTitle: 'Sikeres megrendelés',
    successfulOrderContent: 'Köszönjük, hogy a mi csomagküldő szolgáltatásunkat használja.',
    newOrder: 'Új megrendelés',
    cash: 'Készpénz',
    wireTransfer: 'Utalás',
    ticket: 'Jegy',
    actual_balance: 'Folyó egyenleg'
  },
  de: {
    order: 'Auftrag aufgeben',
    pickUp: 'Abholung',
    handover: 'Übergabe',
    orderInfo: 'Auftragsinfo',
    summary: 'Zusammenfassung',
    setEarliestPickUpTime: 'Benutzerdefinierte früheste Abholzeit einrichten',
    dateTime: 'Datum / Zeit',
    cod: 'Per Nachnahme',
    size: 'Größe',
    address: 'Adresse',
    contact: 'Kontakt',
    phone: 'Telefon',
    companyName: 'Unternehmen',
    note: 'Notiz',
    time: 'Zeit',
    service: 'Service',
    paymentType: 'Zahlungsart',
    billingPartner: 'Rechnungspartner',
    price: 'Preis',
    gross: 'Brutto',
    successfulOrderTitle: 'Erfolgreiche Bestellung',
    successfulOrderContent: 'Danke, dass Sie sich für unseren Lieferservice entschieden haben!',
    newOrder: 'Neuer Auftrag',
    cash: 'Bar',
    wireTransfer: 'Wire Transfer',
    ticket: 'Ticket',
    actual_balance: 'Aktueller Saldo'
  },
  it: {
    order: 'Effettua un ordine',
    pickUp: 'Ritiro',
    handover: 'Consegna',
    orderInfo: 'Informazioni sull\'ordine',
    summary: 'Riepilogo',
    setEarliestPickUpTime: 'Imposta il primo orario di ritiro personalizzato',
    dateTime: 'Data / Ora',
    cod: 'Pagamento alla consegna',
    size: 'Misura',
    address: 'Indirizzo',
    contact: 'Contatto',
    phone: 'Telefono',
    companyName: 'Nome azienda',
    note: 'Note',
    time: 'Ora',
    service: 'Servizio',
    paymentType: 'Modalità di pagamento',
    billingPartner: 'Partner di fatturazione',
    price: 'Prezzo',
    gross: 'Lordo',
    successfulOrderTitle: 'Ordine completato',
    successfulOrderContent: 'Grazie per aver scelto il nostro servizio di consegna!',
    newOrder: 'Nuovo ordine',
    cash: 'Contanti',
    wireTransfer: 'Bonifico bancario',
    ticket: 'Tagliando',
    actual_balance: 'Bilancio attuale'
  },
  ro: {
    order: 'Livrarea comenzii',
    pickUp: 'Ridicare',
    handover: 'Predare',
    orderInfo: 'Informații comandă',
    summary: 'Sumar',
    setEarliestPickUpTime: 'Setează timpul de ridicare cel mai apropiat',
    dateTime: 'Data / Ora',
    cod: 'Numerar la livrare',
    size: 'Dimensiune',
    address: 'Adresa',
    contact: 'Contact',
    phone: 'Telefon',
    companyName: 'Numele companiei',
    note: 'Notă',
    time: 'Ora',
    service: 'Serviciu',
    paymentType: 'Modalitatea de plată',
    billingPartner: 'Partenerul de facturare',
    price: 'Preț',
    gross: 'Preț brut',
    successfulOrderTitle: 'Comandă reușită',
    successfulOrderContent: 'Mulțumim că ați ales serviciul nostru de livrare!',
    newOrder: 'Comandă nouă',
    cash: 'Numerar',
    wireTransfer: 'Transfer bancar',
    ticket: 'Ticket',
    actual_balance: 'Balanta actuala'
  },
})

export default order
