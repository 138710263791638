import { gql } from '@apollo/client'

const CREATE_CLIENT = gql`
  mutation CreateClient($input: CreateClientInput!) {
    createClient(input: $input) {
      client_id
      first_name
      last_name
      email
      partners {
        actual_balance
      }
    }
  }
`
const SAVE_CLIENT_PORTAL_ORDER_TEMPLATE = gql`
  mutation SaveClientPortalOrderTemplate($input: ClientPortalOrderTemplateInput!) {
    saveClientPortalOrderTemplate(input: $input) {
      client_portal_order_template_id
      service {
        service_id
      }
      size {
        size_id
      }
      default
      client_portal_delivery_point_templates {
        zip_code
        event
        city
        address
        floor_and_door
        first_name
        last_name
        phone_country_prefix
        phone_number
        company_name
        note
        skip_this_step
        cash_on_delivery
        order
      }
    }
  }
`

export { CREATE_CLIENT, SAVE_CLIENT_PORTAL_ORDER_TEMPLATE }
