import './Menu.scss'

import MenuItem from './MenuItem/MenuItem'
import PackagesIcon from '#components/CustomIcons/PackagesIcon/PackagesIcon'
import React from 'react'
import SettingsIcon from '@material-ui/icons/Settings'
import useLang from 'src/hooks/useLang'

const Menu = () => {
  const lang = useLang(['menu'])

  const menuItems = [
    {
      title: lang.menu.deliveries,
      route: '/deliveries',
      icon: <PackagesIcon />,
    },
    {
      title: lang.menu.settings,
      route: '/settings',
      icon: <SettingsIcon />,
    },
  ]

  return (
    <div className="Menu">
      {menuItems.map((props, index) => (
        <MenuItem {...props} key={`menu-item-${index}`} />
      ))}
    </div>
  )
}

export default Menu
