import { Slide, Snackbar as MUISnackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React from 'react'

const Snackbar = props => {
  const {
    open,
    message,
    autoHideDuration = 5000,
    severity = 'success',
    handleClose,
  } = props

  const SlideTransition = props => <Slide {...props} direction="left" />

  return (
    <MUISnackbar
      open={open}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      autoHideDuration={autoHideDuration}
      TransitionComponent={SlideTransition}
      onClose={handleClose}
    >
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </MUISnackbar>
  )
}

export default Snackbar
