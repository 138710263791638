import * as authApi from '#api/auth'
import * as browserStorage from '#utils/browser-storage'

import {
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  FormHelperText,
  Link,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { CREATE_CLIENT } from '#graphql/mutators/client'
import ClientContext from '#context/client'
import Logo from '#components/Logo/Logo'
import SettingsContext from '#context/settings'
import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import { useFormik } from 'formik'
import useLang from 'src/hooks/useLang'
import { useMutation } from '@apollo/client'
import useYup from '#hooks/useYup'

const RegistrationPage = () => {
  const [token, setToken] = useState('')

  const history = useHistory()
  const location = useLocation()

  const clientContext = useContext(ClientContext)
  const { settings } = useContext(SettingsContext)
  const lang = useLang(['validation', 'homepage'])

  const setRegistrationToken = () => {
    const ulrParams = queryString.parse(location.search)

    setToken(ulrParams.token)
  }

  useEffect(setRegistrationToken, [])

  const [createClient] = useMutation(CREATE_CLIENT, {
    errorPolicy: 'all',
  })

  const yup = useYup()

  // TODO[Ricsi]: validalni, hogy ne lehessen olyan email cim, ami mar szerepel az adatbazisban
  const validationSchema = yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().required().email(),
    password: yup
      .string()
      .required()
      .min(5)
      // TODO[Ricsi]: egyeztetni, hogy mit tartalmazzon a jelszo
      .matches(/[1-9]+/, lang.validation.passwordPattern),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref('password'), null]),
    privacyPolicy: yup.boolean().oneOf([true], lang.validation.privacyPolicyNotChecked),
  })

  const { touched, errors, handleSubmit, getFieldProps, setErrors } = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      privacyPolicy: false,
    },
    validationSchema,
    onSubmit: values => {
      save(values)
    },
  })

  const resetTokenError = () => {
    setTimeout(() => {
      setErrors({
        ...errors,
        token: '',
      })
    }, 5000)
  }

  const save = values => {
    if (isEmpty(token)) {
      setErrors({
        token: lang.homepage.missingRegistrationToken,
      })

      resetTokenError()

      return false
    }

    const { firstName, lastName, email, password } = values

    createClient({
      variables: {
        input: {
          first_name: firstName,
          last_name: lastName,
          email: email,
          password: password,
          registration_token: token,
        },
      },
    }).then(response => {
      if (response.errors) {
        handleErrors(response)
      } else {
        login(values, response.data.createClient)
      }
    })
  }

  const login = async (values, client) => {
    await authApi.login(values).then(response => {
      // TODO[Ricsi]: tokeneket menteni
      browserStorage.store(response.data)
      clientContext.setClient(client)
      history.push('/deliveries')
    })
  }

  const handleErrors = response => {
    Object.values(response?.errors[0]?.extensions?.validation).forEach(validations => {
      if (validations.includes('email:unique')) {
        setErrors({
          email: lang.homepage.takenEmail,
        })
      }

      if (validations.includes('registration_token:exists')) {
        setErrors({
          token: lang.homepage.invalidRegistrationToken,
        })

        resetTokenError()
      }
    })
  }

  const handleClickLink = event => {
    event.preventDefault()

    history.push('/login')
  }

  return (
    <Container className="RegistrationPage page" maxWidth="md">
      <Paper className="auth-block" elevation={3} square>
        <Logo className="mb-30" />

        <Typography className="mb-30" variant="h5">
          {lang.homepage.createAccountTitle}
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            className="mb-15"
            label={lang.homepage.firstName}
            fullWidth
            error={touched.firstName && !!errors.firstName}
            helperText={touched.firstName && errors.firstName}
            {...getFieldProps('firstName')}
          />

          <TextField
            className="mb-15"
            label={lang.homepage.lastName}
            fullWidth
            error={touched.lastName && !!errors.lastName}
            helperText={touched.lastName && errors.lastName}
            {...getFieldProps('lastName')}
          />

          <TextField
            className="mb-15"
            label={lang.homepage.email}
            fullWidth
            error={touched.email && !!errors.email}
            helperText={touched.email && errors.email}
            {...getFieldProps('email')}
          />

          <TextField
            className="mb-15"
            label={lang.homepage.password}
            fullWidth
            type="password"
            error={touched.password && !!errors.password}
            helperText={touched.password && errors.password}
            {...getFieldProps('password')}
          />

          <TextField
            className="mb-25"
            label={lang.homepage.confirmPassword}
            fullWidth
            type="password"
            error={touched.confirmPassword && !!errors.confirmPassword}
            helperText={touched.confirmPassword && errors.confirmPassword}
            {...getFieldProps('confirmPassword')}
          />

          <FormControlLabel
            label={lang.homepage.formatString(lang.homepage.accept, {
              link: (
                <Link href={settings.general_terms_and_conditions_url} target="_blank">
                  {lang.homepage.privacyPolicy}
                </Link>
              ),
            })}
            control={<Checkbox color="primary" checked={getFieldProps('privacyPolicy')['value']} />}
            {...getFieldProps('privacyPolicy')}
          />

          <FormHelperText className="mb-20" error={true}>
            {errors.privacyPolicy}
          </FormHelperText>

          {errors.token && (
            <FormHelperText className="mb-20" error={true}>
              {errors.token}
            </FormHelperText>
          )}

          <Button className="mb-30" fullWidth variant="contained" color="primary" type="submit">
            {lang.homepage.createAccount}
          </Button>
        </form>

        <Typography variant="body1">
          <span className="mr-5">{lang.homepage.haveAnAccount}</span>
          <Link onClick={handleClickLink}>{lang.homepage.signIn}</Link>
        </Typography>
      </Paper>
    </Container>
  )
}

export default RegistrationPage
