import LocalizedStrings from 'react-localization'

let sidebar = new LocalizedStrings({
  en: {
    clientPortal: 'Client Portal',
    order: 'Order delivery',
    loggedInAs: 'Logged in as',
    logout: 'Logout',
    actualBalance: 'Actual balance'
  },
  hu: {
    clientPortal: 'Ügyfélportál',
    order: 'Megrendelés',
    loggedInAs: 'Bejelentkezve',
    logout: 'Kijelentkezés',
    actualBalance: 'Folyó egyenleg'
  },
  de: {
    clientPortal: 'Kundenportal',
    order: 'Auftrag aufgeben',
    loggedInAs: 'Eingeloggt als',
    logout: 'Logout',
    actualBalance: 'Aktueller Saldo'
  },
  it: {
    clientPortal: 'Portale clienti',
    order: 'Ordina una spedizione',
    loggedInAs: 'Loggato come',
    logout: 'Esci',
    actualBalance: 'Bilancio attuale'
  },
  ro: {
    clientPortal: 'Portal Client',
    order: 'Comandă o livrare',
    loggedInAs: 'Logat ca',
    logout: 'Delogare',
    actualBalance: 'Balanta actuala'
  },
})

export default sidebar
