import './OrderPage.scss'

import { Box, Button, Step, StepContent, StepLabel, Stepper, Typography } from '@material-ui/core'
import {
    CREATE_ORDER_BY_CLIENT,
    PREVIEW_ORDER_BY_CLIENT,
    VALIDATE_PICK_UP_BY_CLIENT,
    VALIDATE_RECIPIENT_BY_CLIENT,
} from '#graphql/mutators/order'
import React, { Fragment, useContext, useEffect, useState } from 'react'

import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ClientContext from '#context/client'
import SettingsContext from '#context/settings'
import { GET_SERVICES } from '#graphql/queries/service'
import { GET_CLIENT } from '#graphql/queries/client'
import Handover from '#pages/OrderPage/Steps/Handover/Handover'
import LoadingButton from '#components/UI/LoadingButton/LoadingButton'
import PageHeader from '#components/PageHeader/PageHeader'
import OrderInfo from '#pages/OrderPage/Steps/OrderInfo/OrderInfo'
import PickUp from '#pages/OrderPage/Steps/PickUp/PickUp'
import Summary from '#pages/OrderPage/Steps/Summary/Summary'
import dayjs from 'dayjs'
import isEmpty from 'lodash/isEmpty'
import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import useLang from 'src/hooks/useLang'
import { useMutation } from '@apollo/client'
import { useQuery } from '@apollo/client'
import useYup from '#hooks/useYup'


let handlingInProgress = false

const OrderPage = () => {
    const [activeStep, setActiveStep] = useState(0)
    const [orderButtonLoading, setOrderButtonLoading] = useState(true)
    const [summaryData, setSummaryData] = useState({})
    const [saved, setSaved] = useState(false)

    const history = useHistory()
    const lang = useLang(['validation', 'homepage', 'order', 'general'])

    const {
        client: { default_client_portal_order_template: orderTemplate },
        setClient
    } = useContext(ClientContext)

    const { settings } = useContext(SettingsContext)

    const [validatePickUpByClient] = useMutation(VALIDATE_PICK_UP_BY_CLIENT, {
        errorPolicy: 'all',
    })

    const [validateRecipientByClient] = useMutation(VALIDATE_RECIPIENT_BY_CLIENT, {
        errorPolicy: 'all',
    })

    const [previewOrderByClient] = useMutation(PREVIEW_ORDER_BY_CLIENT, {
        errorPolicy: 'all',
        onCompleted() {
            setTimeout(() => {
                setOrderButtonLoading(false)
            }, 1000);
        }
    })

    const [createOrderByClient] = useMutation(CREATE_ORDER_BY_CLIENT, {
        errorPolicy: 'all',
    })

    const { data: servicesData } = useQuery(GET_SERVICES, {
        onCompleted() {
            const defaultService = servicesData.clientServices.find(service => service.default);

            if (defaultService) {
                setFieldValue('orderInfo.service', servicesData.clientServices.find(service => service.default).service_id)
            }
        }
    })

    useQuery(GET_CLIENT, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setClient(data.client);
            setFieldValue('orderInfo.service', orderTemplate?.service?.service_id)
            setFieldValue('orderInfo.size', orderTemplate?.size?.size_id)

            if (orderTemplate?.client_portal_delivery_point_templates[0].skip_this_step && isEmpty(errors.pickUp)) {
                handleNext();
            }
        },
    })

    const now = dayjs().format('YYYY-MM-DDTHH:mm')

    const yup = useYup()

    const addressValidationSchema = yup.object({
        zipCode: settings.zip_code_input === 'REQUIRED' ? yup.string().required() : '',
        city: yup.string().required(),
        address: yup.string().required(),
        email: yup.string().email(),
    })

    const validationSchema = yup.object({
        pickUp: addressValidationSchema,
        handover: addressValidationSchema.shape({
            cod: yup.number(),
        }),
        orderInfo: yup.object({
            size: yup.string().required(),
            service: yup.string().required(),
        }),
    })

    const {
        touched,
        errors,
        values,
        getFieldProps,
        handleChange,
        handleReset: formikHandleReset,
        setFieldError,
        setFieldTouched,
        setTouched,
        setFieldValue,
    } = useFormik({
        initialValues: {
            pickUp: {
                zipCode: settings.zip_code_input !== 'HIDDEN' && orderTemplate?.client_portal_delivery_point_templates[0]?.zip_code ? orderTemplate?.client_portal_delivery_point_templates[0]?.zip_code : '',
                city: orderTemplate?.client_portal_delivery_point_templates[0]?.city ?? '',
                address: orderTemplate?.client_portal_delivery_point_templates[0]?.address ?? '',
                floorDoor: orderTemplate?.client_portal_delivery_point_templates[0]?.floor_and_door ?? '',
                firstname: orderTemplate?.client_portal_delivery_point_templates[0]?.first_name ?? '',
                lastname: orderTemplate?.client_portal_delivery_point_templates[0]?.last_name ?? '',
                phone: orderTemplate?.client_portal_delivery_point_templates[0]?.phone_number ?? '',
                email: orderTemplate?.client_portal_delivery_point_templates[0]?.email ?? '',
                companyName: orderTemplate?.client_portal_delivery_point_templates[0]?.company_name ?? '',
                note: orderTemplate?.client_portal_delivery_point_templates[0]?.note ?? '',
                setEarliestPickUpTime: false,
                earliestPickUpTime: now,
            },
            handover: {
                zipCode: settings.zip_code_input !== 'HIDDEN' && orderTemplate?.client_portal_delivery_point_templates[1]?.zip_code ? orderTemplate?.client_portal_delivery_point_templates[1]?.zip_code : '',
                city: orderTemplate?.client_portal_delivery_point_templates[1]?.city ?? '',
                address: orderTemplate?.client_portal_delivery_point_templates[1]?.address ?? '',
                floorDoor: orderTemplate?.client_portal_delivery_point_templates[1]?.floor_and_door ?? '',
                firstname: orderTemplate?.client_portal_delivery_point_templates[1]?.first_name ?? '',
                lastname: orderTemplate?.client_portal_delivery_point_templates[1]?.last_name ?? '',
                phone: orderTemplate?.client_portal_delivery_point_templates[1]?.phone_number ?? '',
                email: orderTemplate?.client_portal_delivery_point_templates[1]?.email ?? '',
                companyName: orderTemplate?.client_portal_delivery_point_templates[1]?.company_name ?? '',
                note: orderTemplate?.client_portal_delivery_point_templates[1]?.note ?? '',
                cod: '',
            },
            orderInfo: {
                size: orderTemplate?.size_id ?? '',
                service: orderTemplate?.service_id ?? ''
            },
        },
        validationSchema
    })

    const touchPickUpZipCodeIfFilled = () => {
        if (orderTemplate?.zipCode !== '' && orderTemplate?.city !== '' && orderTemplate?.address !== '') {
            setFieldTouched("pickUp.zipCode")
        }
    }

    useEffect(touchPickUpZipCodeIfFilled, [])

    const handleBack = () => {
        setActiveStep(current => --current)
    }

    const handleNext = () => {
        handlingInProgress = true;

        switch (activeStep) {
            case 0:
                validatePickUp(values.pickUp).then(response => {
                    if (response.errors) {
                        handleErrors(response, 'pickUp')
                    } else {
                        setActiveStep(current => ++current)

                        if (values.handover.city !== '') {
                            setFieldTouched("handover.city");
                        }

                        handlingInProgress = false;
                    }
                })
                break

            case 1:
                validateHandover(values.handover).then(response => {
                    if (response.errors) {
                        handleErrors(response, 'handover')
                    } else {
                        setActiveStep(current => ++current)
                        if (values.orderInfo.size !== '') {
                            setFieldTouched("orderInfo.size");
                        }
                    }
                    handlingInProgress = false;
                })
                break

            case 2:
                validateOrder(values).then(response => {
                    if (response.errors) {
                        handleErrors(response)
                    } else {
                        setSummaryData(response.data.previewOrderByClient)
                        setActiveStep(current => ++current)
                    }
                    handlingInProgress = false;
                })
                break

            default:
                handlingInProgress = false;
        }
    }

    // TODO[Ricsi]: osszevonni
    const validatePickUp = data => {
        return validatePickUpByClient({
            variables: {
                input: {
                    address: {
                        zip_code: data.zipCode,
                        city: data.city,
                        address: data.address,
                        country: '',
                        floor_and_door: data.floorDoor,
                    },
                    contact: {
                        first_name: data.firstname,
                        last_name: data.lastname,
                        phone: data.phone,
                        email: data.email
                    },
                    company_name: data.companyName,
                    note: data.note,
                    earliest_pick_up_date_time: data.setEarliestPickUpTime
                        ? dayjs(data.earliestPickUpTime).format('YYYY-MM-DD HH:mm:ss')
                        : null,
                },
            },
        })
    }

    // TODO[Ricsi]: osszevonni
    const validateHandover = data => {
        return validateRecipientByClient({
            variables: {
                input: {
                    address: {
                        zip_code: data.zipCode,
                        city: data.city,
                        address: data.address,
                        country: '',
                        floor_and_door: data.floorDoor,
                    },
                    contact: {
                        first_name: data.firstname,
                        last_name: data.lastname,
                        phone: data.phone,
                        email: data.email,
                    },
                    company_name: data.companyName,
                    note: data.note,
                },
            },
        })
    }

    // TODO[Ricsi]: osszevonni
    const validateOrder = data => {
        const { pickUp, handover, orderInfo } = data

        return previewOrderByClient({
            variables: {
                input: {
                    pick_up: {
                        address: {
                            zip_code: pickUp.zipCode,
                            city: pickUp.city,
                            address: pickUp.address,
                            country: '',
                            floor_and_door: pickUp.floorDoor,
                        },
                        contact: {
                            first_name: pickUp.firstname,
                            last_name: pickUp.lastname,
                            phone: pickUp.phone,
                            email: pickUp.email,
                        },
                        company_name: pickUp.companyName,
                        note: pickUp.note,
                        earliest_pick_up_date_time: pickUp.setEarliestPickUpTime
                            ? dayjs(pickUp.earliestPickUpTime).format('YYYY-MM-DD HH:mm:ss')
                            : null,
                    },
                    recipients: [
                        {
                            address: {
                                zip_code: handover.zipCode,
                                city: handover.city,
                                address: handover.address,
                                country: '',
                                floor_and_door: handover.floorDoor,
                            },
                            contact: {
                                first_name: handover.firstname,
                                last_name: handover.lastname,
                                phone: handover.phone,
                                email: handover.email,
                            },
                            company_name: handover.companyName,
                            note: handover.note,
                            cash_on_delivery: handover.cod !== '' ? handover.cod : 0,
                        },
                    ],
                    size_id: orderInfo.size,
                    service_id: orderInfo.service,
                },
            },
        })
    }

    // TODO[Ricsi]: osszevonni
    const save = data => {
        const { pickUp, handover, orderInfo } = data

        return createOrderByClient({
            variables: {
                input: {
                    pick_up: {
                        address: {
                            zip_code: pickUp.zipCode,
                            city: pickUp.city,
                            address: pickUp.address,
                            country: '',
                            floor_and_door: pickUp.floorDoor,
                        },
                        contact: {
                            first_name: pickUp.firstname,
                            last_name: pickUp.lastname,
                            phone: pickUp.phone,
                            email: pickUp.email,
                        },
                        company_name: pickUp.companyName,
                        note: pickUp.note,
                        earliest_pick_up_date_time: pickUp.setEarliestPickUpTime
                            ? dayjs(pickUp.earliestPickUpTime).format('YYYY-MM-DD HH:mm:ss')
                            : null,
                    },
                    recipients: [
                        {
                            address: {
                                zip_code: handover.zipCode,
                                city: handover.city,
                                address: handover.address,
                                country: '',
                                floor_and_door: handover.floorDoor,
                            },
                            contact: {
                                first_name: handover.firstname,
                                last_name: handover.lastname,
                                phone: handover.phone,
                                email: handover.email,
                            },
                            company_name: handover.companyName,
                            note: handover.note,
                            cash_on_delivery: handover.cod !== '' ? handover.cod : 0,
                        },
                    ],
                    size_id: orderInfo.size,
                    service_id: orderInfo.service
                },
            },
        })
    }

    const handleErrors = (response, section = null) => {
        if (isEmpty(response?.errors[0]?.extensions?.validation)) {
            alert('Something went wrong. Please refresh the page and try again.')
            return
        }

        setFieldTouched(`${section}.phone`)
        setFieldTouched(`${section}.email`)
        setFieldTouched(`${section}.address`)
        setFieldTouched(`${section}.setEarliestPickUpTime`)

        Object.values(response?.errors[0]?.extensions?.validation).forEach(validations => {
            if (validations.includes('address_cannot_be_geocoded')) {
                setFieldError(`${section}.address`, lang.validation.addressInvalid)
            }

            if (validations.includes('invalid_phone_format')) {
                setFieldError(`${section}.phone`, lang.validation.phoneInvalid)
            }

            if (validations.includes('it_should_be_later_than_now')) {
                setFieldError(`${section}.setEarliestPickUpTime`, lang.validation.dateTimeInvalid)
            }

            if (validations.includes('address_is_out_of_the_service_area')) {
                let errorMessage = lang.validation.pickUpAddressNotFitToService;

                if ('recipients.0.address' in response?.errors[0]?.extensions?.validation) {
                    errorMessage = lang.validation.recipientAddressNotFitToService;
                }

                showServiceErrorMessage(errorMessage)
            }

            if (validations.includes('service_not_exists')) {
                let errorMessage = lang.validation.serviceNotAvailable;

                showServiceErrorMessage(errorMessage)
            }
        })
    }

    const showServiceErrorMessage = (errorMessage) => {
        setActiveStep(2)
        setFieldTouched('orderInfo.service')
        setFieldError('orderInfo.service', errorMessage)
    }

    const handleReset = () => {
        formikHandleReset()
        setActiveStep(0)
        setSaved(false)
        touchPickUpZipCodeIfFilled()
    }

    const handleEdit = stepIndex => {
        setActiveStep(stepIndex)
    }

    const handleOrder = () => {
        setOrderButtonLoading(true)

        save(values).then(response => {
            setOrderButtonLoading(false)

            if (response.errors) {
                handleErrors(response)
                return;
            }

            setSaved(true)
        })
    }

    const steps = [
        {
            section: 'pickUp',
            content: (
                <PickUp
                    section="pickUp"
                    touched={touched?.pickUp ?? {}}
                    errors={errors?.pickUp ?? {}}
                    values={values?.pickUp ?? {}}
                    getFieldProps={getFieldProps}
                    description={settings.pick_up_description ?? ''}
                />
            ),
        },
        {
            section: 'handover',
            content: (
                <Handover
                    section="handover"
                    touched={touched?.handover ?? {}}
                    errors={errors?.handover ?? {}}
                    getFieldProps={getFieldProps}
                    setFieldValue={setFieldValue}
                    description={settings.handover_description ?? ''}
                />
            ),
        },
        {
            section: 'orderInfo',
            content: (
                <OrderInfo
                    section="orderInfo"
                    touched={touched?.orderInfo ?? {}}
                    errors={errors?.orderInfo ?? {}}
                    services={servicesData?.clientServices}
                    getFieldProps={getFieldProps}
                    values={values?.orderInfo ?? null}
                    handleChange={handleChange}
                    description={settings.order_info_description ?? ''}
                />
            ),
        },
        {
            section: 'summary',
            content: <Summary data={summaryData} handleEdit={handleEdit} description={settings.summary_description ?? ''} />,
        },
    ]

    return (
        <div className="OrderPage page">
            <PageHeader
                title={lang.order.order} />

            <div className="order-content">
                {!saved ? (
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                            <Step key={`step-${index}`}>
                                <StepLabel>
                                    <Typography className="ml-20" variant="h6">
                                        {lang.order[step.section]}
                                    </Typography>
                                </StepLabel>
                                <StepContent className="pl-40 pt-20">
                                    <Fragment>
                                        {step.content}

                                        <div className="button-container">
                                            {activeStep === steps.length - 1 && <Button onClick={handleReset}>{lang.general.cancel}</Button>}
                                            <div className="right-button-group">
                                                {activeStep > 0 && (
                                                    <Button className="mr-15" onClick={handleBack}>
                                                        {lang.general.back}
                                                    </Button>
                                                )}
                                                {activeStep < steps.length - 1 && (
                                                    <Button
                                                        color="primary"
                                                        disabled={isEmpty(touched[step.section]) || !isEmpty(errors[step.section]) || handlingInProgress}
                                                        onClick={handleNext}
                                                    >
                                                        {lang.general.next}
                                                    </Button>
                                                )}
                                                {activeStep === steps.length - 1 && (
                                                    <LoadingButton loading={orderButtonLoading} onClick={handleOrder}>
                                                        {lang.order.order}
                                                    </LoadingButton>
                                                )}
                                            </div>
                                        </div>
                                    </Fragment>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                ) : (
                    <div className="successful-order-content">
                        <div className="mb-20">
                            <Box color="success.main" fontSize={44}>
                                <CheckCircleIcon fontSize="inherit" />
                            </Box>

                            <div>
                                <Typography>
                                    <strong>{lang.order.successfulOrderTitle}</strong>
                                </Typography>
                                <Typography>{lang.order.successfulOrderContent}</Typography>
                            </div>
                        </div>

                        <div className="text-right">
                            <Button className="mr-15" onClick={handleReset}>
                                {lang.order.newOrder}
                            </Button>
                            <Button variant="contained" color="primary" onClick={() => history.push('/deliveries')}>
                                {lang.general.ok}
                            </Button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default OrderPage
