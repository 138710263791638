import { Button, CircularProgress } from '@material-ui/core'
import React, { Fragment } from 'react'
import classnames from 'classnames'

import './LoadingButton.scss'

const LoadingButton = props => {
  const { className, loading, color, variant, ...rest } = props
  const classes = classnames('LoadingButton', className, { loading: loading })

  return (
    <Button
      className={classes}
      variant={variant ? variant : "contained"}
      color={color ? color : "primary"}
      disabled={loading}
      {...rest}
    >
      <Fragment>
        {loading && <CircularProgress size={24} />}
        <div className="label">{props.children}</div>
      </Fragment>
    </Button>
  )
}

export default LoadingButton
