import LocalizedStrings from 'react-localization'

let validation = new LocalizedStrings({
  en: {
    required: 'Required.',
    min: 'The value must be at least {0} characters.',
    number: 'The value must be a number.',
    invalidType: 'The value of the a field is invalid.',
    email: 'The email address is invalid.',
    emailTaken: 'This email address is taken.',
    passwordsNotMatch: 'Passwords are not match.',
    passwordPattern: 'Password must contain at least a number.',
    privacyPolicyNotChecked: 'You have to accept our Privacy Policy.',
    addressInvalid: 'The address is invalid.',
    phoneInvalid: 'The phone number is invalid (Format: +36301234567).',
    emailNotExists: "The email address doesn't exist in the database.",
    dateTimeInvalid: 'The date / time has to be later then now.',
    pickUpAddressNotFitToService: 'The pick up address doesn\'t fit to the selected service. Choose another service or change the address.',
    recipientAddressNotFitToService: 'The recipient address doesn\'t fit to the selected service. Choose another service or change the address.',
    serviceNotAvailable: 'This service not available.'
  },
  hu: {
    required: 'Kötelező.',
    min: 'Az értéknek legalább {0} karakterből kell állnia.',
    number: 'Az érték csak szám lehet.',
    invalidType: 'A mező értéke érvénytelen.',
    email: 'A megadott email cím nem érvényes.',
    emailTaken: 'A megadott email cím már használatban van.',
    passwordsNotMatch: 'A megadott jelszavak nem egyeznek meg.',
    passwordPattern: 'A jelszónak tartalmazni kell legalább egy számot.',
    privacyPolicyNotChecked: 'El kell fogadnod az Adatvédelmi irányelveket.',
    addressInvalid: 'A megadott cím hibás.',
    phoneInvalid: 'A megadott telefonszám hibás (Formátum: +36301234567).',
    emailNotExists: 'A megadott email cím nem szerepel az adatbázisban.',
    dateTimeInvalid: 'Az dátum / időpont nem lehet korábban és nem egyezhet meg a jelenlegi időponttal.',
    pickUpAddressNotFitToService: 'A felvételi cím nincs benne a kiválasztott szolgáltatás területében. Válassz másik szolgáltatást, vagy cseréld le a címet.',
    recipientAddressNotFitToService: 'A leadási cím nincs benne a kiválasztott szolgáltatás területében. Válassz másik szolgáltatást, vagy cseréld le a címet.',
    serviceNotAvailable: 'Ez a szolgáltatás nem elérhető.'
  },
  de: {
    required: 'Erforderlich',
    min: 'Die Eingabe muss mindestens {0} Zeichen lang sein.',
    number: 'Die Eingabe muss eine Zahl sein.',
    invalidType: 'Der Wert des Feldes a ist ungültig.',
    email: 'Die E-Mail Adresse ist ungültig.',
    emailTaken: 'Diese E-Mail Adresse wird bereits verwendet.',
    passwordsNotMatch: 'Die Passwörter stimmen nicht überein.',
    passwordPattern: 'Das Passwort muss mindestens eine Zahl enthalten.',
    privacyPolicyNotChecked: 'Sie müssen unsere Datenschutzbestimmungen akzeptieren.',
    addressInvalid: 'Die Adresse ist ungültig.',
    phoneInvalid: 'Die Telefonnummer ist ungültig (Format: +36301234567).',
    emailNotExists: 'Die E-Mail Adresse existiert nicht in unserer Datenbank.',
    dateTimeInvalid: 'Das Datum / die Uhrzeit muss später als jetzt sein.',
    pickUpAddressNotFitToService: 'Die Abholadresse passt nicht zum gewählten Dienst. Wählen Sie einen anderen Service oder ändern Sie die Adresse.',
    recipientAddressNotFitToService: 'Die Empfängeradresse passt nicht zum gewählten Dienst. Wählen Sie einen anderen Dienst oder ändern Sie die Adresse.',
    serviceNotAvailable: 'Dieser Dienst ist nicht verfügbar.',
  },
  it: {
    required: 'Richiesto.',
    min: 'Il valore deve contenere almeno {0} caratteri.',
    number: 'Il valore deve essere un numero.',
    invalidType: 'I valori utilizzati in questo campo non sono validi.',
    email: 'L\'indirizzo e- mail non è valido.',
    emailTaken: 'Questo indirizzo e-mail è già in uso.',
    passwordsNotMatch: 'Le password non corrispondono.',
    passwordPattern: 'La password deve contenere almeno un numero',
    privacyPolicyNotChecked: 'Devi accettare la nostra Informativa sulla privacy.',
    addressInvalid: 'L\'indirizzo non è valido.',
    phoneInvalid: 'Il numero di telefono non è valido (Formato: +36301234567).',
    emailNotExists: 'L\'indirizzo e- mail non esiste nel database.',
    dateTimeInvalid: 'La data / ora deve essere successiva a quella attuale.',
    pickUpAddressNotFitToService: 'L\'indirizzo di ritiro non  va bene per il servizio selezionato.Scegli un altro servizio o modifica l\'indirizzo.',
    recipientAddressNotFitToService: 'L\'indirizzo del destinatario non va bene per il servizio selezionato.Scegli un altro servizio o modifica l\'indirizzo.',
    serviceNotAvailable: 'Questo servizio non è disponibile.',
  },
  ro: {
    required: 'Câmp obligatoriu',
    min: 'Valoarea trebuie să aibă minim {0} caractere.',
    number: 'Valoarea trebuie să fie un număr.',
    invalidType: 'Valoarea unui câmp este invalidă.',
    email: 'Adresa de email este invalidă.',
    emailTaken: 'Adresa de email este deja folosită.',
    passwordsNotMatch: 'Parolele nu se potrivesc.',
    passwordPattern: 'Parola trebuie să conțină cel puțin un număr.',
    privacyPolicyNotChecked: 'Trebuie să acceptați Politica De Confidențialitate.',
    addressInvalid: 'Adresa este invalidă.',
    phoneInvalid: 'Numărul de telefon este invalid (format: +36301234567).',
    emailNotExists: 'Adresa de e-mail nu există în baza de date.',
    dateTimeInvalid: 'Data / ora trebuie să fie mai târziu decât momentul actual.',
    pickUpAddressNotFitToService: 'Adresa de ridicare nu corespunde serviciului selectat. Alege alt serviciu sau schimba adresa.',
    recipientAddressNotFitToService: 'Adresa de livrare nu corespunde serviciului selectat. Alege alt serviiciu sau schimba adresa.',
    serviceNotAvailable: 'Acest serviciu nu este valabil.',
  },
})

export default validation
