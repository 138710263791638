import React, { useContext } from 'react'
import SettingsContext from '#context/settings'
import Menu from '#components/Menu/Menu'
import SidebarHeader from './SidebarHeader/SidebarHeader'
import SidebarFooter from './SidebarFooter/SidebarFooter'
import CO2Savings from '#components/CO2Savings/CO2Savings'
import ActualBalance from '#components/ActualBalance/ActualBalance'

import './Sidebar.scss'

const Sidebar = props => {
  const { settings } = useContext(SettingsContext)

  let CO2SavingsComponent = ''
  let ActualBalanceComponent = ''

  if (settings.show_co2_saving) {
    CO2SavingsComponent = <CO2Savings />
  }

  if (settings.show_partner_actual_balance) {
    ActualBalanceComponent = <ActualBalance />
  }

  return (
    <div className="Sidebar">
      <SidebarHeader />
      <Menu />
      {CO2SavingsComponent}
      {ActualBalanceComponent}
      <SidebarFooter />
    </div>
  )
}

export default Sidebar
