import { gql } from '@apollo/client'

const CREATE_PASSWORD_RESET_TOKEN_BY_CLIENT = gql`
  mutation CreatePasswordResetTokenByClient($input: CreatePasswordResetTokenByClientInput!) {
    createPasswordResetTokenByClient(input: $input)
  }
`

const SET_NEW_PASSWORD_WITH_TOKEN_BY_CLIENT = gql`
  mutation SetNewPasswordWithTokenByClient($input: SetNewPasswordWithTokenByClientInput!) {
    setNewPasswordWithTokenByClient(input: $input)
  }
`

export { CREATE_PASSWORD_RESET_TOKEN_BY_CLIENT, SET_NEW_PASSWORD_WITH_TOKEN_BY_CLIENT }
