import * as authApi from '#api/auth'
import * as browserStorage from '#utils/browser-storage'

import { Container, Link, Paper, TextField, Typography } from '@material-ui/core'
import React, { useContext, useState } from 'react'

import ClientContext from '#context/client'
import { GET_CLIENT } from '#graphql/queries/client'
import LoadingButton from '#components/UI/LoadingButton/LoadingButton'
import Logo from '#components/Logo/Logo'
import { useFormik } from 'formik'
import useLang from '#hooks/useLang'
import { useLazyQuery } from '@apollo/client'
import useYup from '#hooks/useYup'

const LoginPage = props => {
  const { history } = props

  const [loginButtonLoading, setLoginButtonLoading] = useState(false)

  const clientContext = useContext(ClientContext)

  const lang = useLang(['homepage'])

  const [getClient] = useLazyQuery(GET_CLIENT, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      clientContext.setClient(data.client)

      history.push('/deliveries')
    },
  })

  const yup = useYup()

  const validationSchema = yup.object({
    email: yup.string().required().email(),
    password: yup.string().required(),
  })

  const { touched, errors, handleSubmit, getFieldProps, setErrors } = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema,
    onSubmit: values => {
      login(values)
    },
  })

  const login = async values => {
    setLoginButtonLoading(true)

    await authApi
      .login(values)
      .then(response => {
        browserStorage.store(response.data)

        getClient()
      })
      .catch(() => {
        setErrors({
          password: lang.homepage.invalidEmailOrPassword,
        })
      })

    setLoginButtonLoading(false)
  }

  const handleClickLink = event => {
    event.preventDefault()

    history.push('/password-reset')
  }

  return (
    <Container className="LoginPage page" maxWidth="md">
      <Paper className="auth-block" elevation={3} square>
        <Logo className="mb-30" />

        <Typography className="mb-30" variant="h5">
          {lang.homepage.signInTitle}
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            className="mb-15"
            label={lang.homepage.email}
            fullWidth
            error={touched.email && !!errors.email}
            helperText={touched.email && errors.email}
            {...getFieldProps('email')}
          />

          <TextField
            className="mb-15"
            label={lang.homepage.password}
            fullWidth
            type="password"
            error={touched.password && !!errors.password}
            helperText={touched.password && errors.password}
            {...getFieldProps('password')}
          />

          <LoadingButton className="mb-30" fullWidth type="submit" loading={loginButtonLoading}>
            {lang.homepage.signIn}
          </LoadingButton>
        </form>

        <Typography variant="body1">
          <Link onClick={handleClickLink}>{lang.homepage.forgotPassword}</Link>
        </Typography>
      </Paper>
    </Container>
  )
}

export default LoginPage
