import LocalizedStrings from 'react-localization'

let address = new LocalizedStrings({
  en: {
    zipCode: 'Zip code',
    city: 'City',
    address: 'Address',
    floorDoor: 'Floor / door',
    firstname: 'Firstname',
    lastname: 'Lastname',
    phone: 'Phone',
    email: 'E-mail',
    companyName: 'Company name',
    note: 'Note',
  },
  hu: {
    zipCode: 'Irányítószám',
    city: 'Város',
    address: 'Cím',
    floorDoor: 'Emelet / ajtó',
    firstname: 'Vezetéknév',
    lastname: 'Keresztnév',
    phone: 'Telefonszám',
    email: 'E-mail',
    companyName: 'Cégnév',
    note: 'Megjegyzés',
  },
  de: {
    zipCode: 'PLZ',
    city: 'Stadt',
    address: 'Adresse',
    floorDoor: 'Etage / Tür',
    firstname: 'Vorname',
    lastname: 'Nachname',
    phone: 'Phone',
    email: 'E-mail',
    companyName: 'Unternehmen',
    note: 'Notiz',
  },
  it: {
    zipCode: 'CAP',
    city: 'Città',
    address: 'Indirizzo',
    floorDoor: 'Piano',
    firstname: 'Nome',
    lastname: 'Cognome',
    phone: 'Telefono',
    email: 'E-mail',
    companyName: 'Nome Azienda',
    note: 'Note',
  },
  ro: {
    zipCode: 'Cod Poștal',
    city: 'Oraș',
    address: 'Adresă',
    floorDoor: 'Etaj / Apartament',
    firstname: 'Prenume',
    lastname: 'Nume',
    phone: 'Phone',
    email: 'E-mail',
    companyName: 'Numele Companiei',
    note: 'Observații',
  },
})

export default address
