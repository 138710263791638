import { gql } from '@apollo/client'

const CREATE_PACKAGE_ID_STICKERS_DOWNLOAD_URL = gql`
  mutation createPackageIdStickersDownloadURL($input: CreatePackageIdStickersDownloadURLInput!) {
    createPackageIdStickersDownloadURL(input: $input) {
      download_url
    }
  }
`

export { CREATE_PACKAGE_ID_STICKERS_DOWNLOAD_URL }
