import LocalizedStrings from 'react-localization'

let settings = new LocalizedStrings({
  en: {
    settings: 'Settings',
    defaultPickUp: 'Default pick up',
    successfulSaveMessage: 'Settings have been saved successfully.',
    defaultHandover: 'Default handover',
    defaultOrderInfo: 'Default order info',
    skipThisStep: 'Skip this step if it\'s valid',
  },
  hu: {
    settings: 'Beállítások',
    defaultPickUp: 'Alapértelmezett felvételi',
    successfulSaveMessage: 'Sikeres mentés.',
    defaultHandover: 'Alapértelmezett átadás',
    defaultOrderInfo: 'Alapértelmezett megrendelés adatok',
    skipThisStep: 'Lépés kihagyása ha az adatok megfelőek',
  },
  de: {
    settings: 'Einstellungen',
    defaultPickUp: 'Standard-Abholung',
    successfulSaveMessage: 'Einstellungen wurden erfolgreich gespeichert.',
    defaultHandover: 'Standardeinstellung Übergabe',
    defaultOrderInfo: 'Standardeinstellung Auftagsinfo',
    skipThisStep: 'Überspringen Sie diesen Schritt bei Gültigkeit',
  },
  it: {
    settings: 'Impostazioni',
    defaultPickUp: 'Ritiro predefinito',
    successfulSaveMessage: 'Le impostazioni sono state salvate con successo.',
    defaultHandover: 'Consegna predefinita',
    defaultOrderInfo: 'Informazioni sull\'ordine predefinito',
    skipThisStep: 'Salta questo passaggio se è valido',
  },
  ro: {
    settings: 'Setări',
    defaultPickUp: 'Ridicare adresă prestabilită',
    successfulSaveMessage: 'Setările au fost salvate cu succes.',
    defaultHandover: 'Ridicare predefinită',
    defaultOrderInfo: 'Predare predefinită',
    skipThisStep: 'Sari peste acest pas daca este valid',
  },
})

export default settings
