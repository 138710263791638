import './AddressBlock.scss'

import React, { useContext } from 'react'
import { TextField } from '@material-ui/core'
import useLang from 'src/hooks/useLang'
import { keys } from '@material-ui/core/styles/createBreakpoints'
import SettingsContext from '#context/settings'

const AddressBlock = props => {
  const { section, touched, errors, getFieldProps, requiredFields = ['city', 'address'], getFieldValue } = props
  const { settings } = useContext(SettingsContext)
  const lang = useLang(['address'])

  let fields = {
    zipCode: {},
    city: {},
    address: {},
    floorDoor: {},
    firstname: {},
    lastname: {},
    phone: {},
    email: {},
    phoneCountryCode: {},
    companyName: {},
    note: {},
  }

  if (settings.zip_code_input === 'REQUIRED' && requiredFields.length !== 0) {
    requiredFields.push('zipCode');
  }

  let checkAutoFocus = true

  Object.keys(fields).map(key => {
    if (key != 'zipCode' || settings.zip_code_input !== 'HIDDEN') {
      fields[key].props = getFieldProps(section ? `${section}.${key}` : key)

      if (checkAutoFocus && !fields[key].props.value) {
        fields[key].props.autoFocus = true
        checkAutoFocus = false
      }
    }
  });


  return (
    <div className="AddressBlock">
      <div className="mb-20 flex line">
        <div className="flex mr-10 flex-6">
          {settings.zip_code_input !== 'HIDDEN' ? (
            <TextField
              className="mr-10 flex-2"
              fullWidth
              required={requiredFields.includes('zipCode')}
              label={lang.address.zipCode}
              error={touched.zipCode && !!errors.zipCode}
              helperText={touched.zipCode && errors.zipCode}
              {...fields.zipCode.props}
            />
          ) : ''}
          <TextField
            className="flex-4"
            fullWidth
            required={requiredFields.includes('city')}
            label={lang.address.city}
            error={touched.city && !!errors.city}
            helperText={touched.city && errors.city}
            {...fields.city.props}
          />
        </div>
        <div className="flex-6 mr-10">
          <TextField
            fullWidth
            required={requiredFields.includes('address')}
            label={lang.address.address}
            error={touched.address && !!errors.address}
            helperText={touched.address && errors.address}
            {...fields.address.props}
          />
        </div>
        <div className="flex-3">
          <TextField
            fullWidth
            label={lang.address.floorDoor}
            {...fields.floorDoor.props}
          />
        </div>
      </div>
      <div className="mb-20 flex line">
        <div className="flex flex-2">
          <TextField
            className="mr-10 flex-1"
            fullWidth
            label={lang.address.firstname}
            {...fields.firstname.props}
          />
          <TextField
            className="flex-1"
            fullWidth
            label={lang.address.lastname}
            {...fields.lastname.props}
          />
        </div>
      </div>
      <div className="mb-20 flex line">
        <div className="flex flex-2">
          <TextField
            className="mr-10 flex-1"
            fullWidth
            label={lang.address.phone}
            error={touched.phone && !!errors.phone}
            helperText={touched.phone && errors.phone}
            {...fields.phone.props}
          />
          <TextField
            className="flex-1"
            fullWidth
            label={lang.address.email}
            error={touched.email && !!errors.email}
            helperText={touched.email && errors.email}
            {...fields.email.props}
          />
        </div>
      </div>
      <div className="mb-20">
        <TextField
          className="flex-1"
          fullWidth
          label={lang.address.companyName}
          error={touched.companyName && !!errors.companyName}
          helperText={touched.companyName && errors.companyName}
          {...fields.companyName.props}
        />
      </div>
      <div className="flex">
        <TextField fullWidth label={lang.address.note} {...fields.note.props} />
      </div>
    </div>
  )
}

export default AddressBlock
