import React, { Suspense } from 'react'
import { AnimatedSwitch } from 'react-router-transition'
import DeliveriesPage from '#pages/DeliveriesPage/DeliveriesPage'
import OrderPage from '#pages/OrderPage/OrderPage'
import SettingsPage from '#pages/SettingsPage/SettingsPage'

import './Content.scss'
import PrivateRoute from '#components/HOC/PrivateRoute'

const Content = () => (
  <div className="Content">
    <Suspense fallback={<div />}>
      <AnimatedSwitch
        atEnter={{ opacity: 0 }}
        atLeave={{ opacity: 0 }}
        atActive={{ opacity: 1 }}
        className="switch-wrapper"
      >
        {/* // TODO[Ricsi]: PrivateRoutes */}
        <PrivateRoute path="/deliveries" component={DeliveriesPage} />
        <PrivateRoute path="/order" component={OrderPage} />
        <PrivateRoute path="/settings" component={SettingsPage} />
      </AnimatedSwitch>
    </Suspense>
  </div>
)

export default Content
