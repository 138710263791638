import { Container, FormHelperText, Paper, TextField, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import LoadingButton from '#components/UI/LoadingButton/LoadingButton'
import Logo from '#components/Logo/Logo'
import { SET_NEW_PASSWORD_WITH_TOKEN_BY_CLIENT } from '#graphql/mutators/password'
import isEmpty from 'lodash/isEmpty'
import queryString from 'query-string'
import { useFormik } from 'formik'
import useLang from 'src/hooks/useLang'
import { useMutation } from '@apollo/client'
import useYup from '#hooks/useYup'

const NewPasswordPage = props => {
  const [token, setToken] = useState()
  const [buttonLoading, setButtonLoading] = useState(false)

  const history = useHistory()
  const location = useLocation()
  const lang = useLang(['validation', 'homepage'])

  const setNewPasswordToken = () => {
    const ulrParams = queryString.parse(location.search)

    setToken(ulrParams.token)
  }

  useEffect(setNewPasswordToken, [])

  const [setNewPasswordWithTokenByClient] = useMutation(SET_NEW_PASSWORD_WITH_TOKEN_BY_CLIENT, {
    errorPolicy: 'all',
  })

  const yup = useYup()

  const validationSchema = yup.object({
    password: yup
      .string()
      .required()
      .min(5)
      // TODO[Ricsi]: egyeztetni, hogy mit tartalmazzon a jelszo
      .matches(/[1-9]+/, lang.validation.passwordPattern),
    confirmPassword: yup
      .string()
      .required()
      .oneOf([yup.ref('password'), null]),
  })

  const { touched, errors, handleSubmit, getFieldProps, setErrors } = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: values => {
      save(values)
    },
  })

  const save = values => {
    if (isEmpty(token)) {
      setErrors({
        token: lang.homepage.missingNewPasswordToken,
      })

      resetTokenError()

      return false
    }

    const { password } = values

    setButtonLoading(true)

    setNewPasswordWithTokenByClient({
      variables: {
        input: {
          token,
          password,
        },
      },
    }).then(response => {
      setButtonLoading(false)

      if (response.errors) {
        handleErrors(response)
      } else {
        history.push('/login')
      }
    })
  }

  const handleErrors = response => {
    Object.values(response?.errors[0]?.extensions?.validation).forEach(validations => {
      if (validations.includes('token_not_exists')) {
        setErrors({
          token: lang.homepage.invalidNewPasswordToken,
        })

        resetTokenError()
      }
    })
  }

  const resetTokenError = () => {
    setTimeout(() => {
      setErrors({
        ...errors,
        token: '',
      })
    }, 5000)
  }

  return (
    <Container className="NewPasswordPage" maxWidth="md">
      <Paper className="auth-block" elevation={3} square>
        <Logo className="mb-30" />

        <Typography className="mb-30" variant="h5">
          {lang.homepage.createNewPassword}
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            className="mb-15"
            label={lang.homepage.password}
            fullWidth
            type="password"
            error={touched.password && !!errors.password}
            helperText={touched.password && errors.password}
            {...getFieldProps('password')}
          />

          <TextField
            className="mb-25"
            label={lang.homepage.confirmPassword}
            fullWidth
            type="password"
            error={touched.confirmPassword && !!errors.confirmPassword}
            helperText={touched.confirmPassword && errors.confirmPassword}
            {...getFieldProps('confirmPassword')}
          />

          {errors.token && (
            <FormHelperText className="mb-20" error={true}>
              {errors.token}
            </FormHelperText>
          )}

          <LoadingButton className="mb-30" fullWidth loading={buttonLoading} type="submit">
            {lang.homepage.confirm}
          </LoadingButton>
        </form>
      </Paper>
    </Container>
  )
}

export default NewPasswordPage
