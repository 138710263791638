import address from '#constants/lang/address'
import deliveries from '#constants/lang/deliveries'
import general from '#constants/lang/general'
import homepage from '#constants/lang/homepage'
import menu from '#constants/lang/menu'
import order from '#constants/lang/order'
import settings from '#constants/lang/settings'
import sidebar from '#constants/lang/sidebar'
import validation from '#constants/lang/validation'
import co2 from '#constants/lang/co2'

const lang = {
  homepage,
  validation,
  sidebar,
  menu,
  deliveries,
  settings,
  address,
  order,
  general,
  co2,
}

export default lang
