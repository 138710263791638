import React from "react"
import { Typography } from "@material-ui/core"

import './PageHeader.scss'

const PageHeader = (props) => {
  const { title } = props

  return (
    <div className="PageHeader mb-40">
      <Typography variant="h4">{title}</Typography>
      {props.children}
    </div>
  );
};

export default PageHeader;
