import { createMuiTheme } from '@material-ui/core/styles'
import grey from '@material-ui/core/colors/grey'

const palette = {
  primary: {
    light: '#ce6160',
    main: '#C23A39',
    dark: '#872827',
  },
  success: {
    light: '#7bcc96',
    main: '#5AC07C',
    dark: '#3e8656',
  },
}

const blue = '#3366CC'

const theme = createMuiTheme({
  palette: palette,
  overrides: {
    MuiStepper: {
      root: {
        padding: 0
      }
    },
    MuiStepIcon: {
      root: {
        '&$active': {
          color: 'black',
        },

        '&$completed': {
          color: palette.success.main,
        },
      },
    },
    MuiStepLabel: {
      label: {
        '&$completed': {
          color: palette.success.main,
        },
      },
    },
    // MuiLink: {
    //   root: {
    //     color: palette.primary.main,
    //   },
    // },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: blue,
        },
      },
    },
    MuiTableContainer: {
      root: {
        border: 'solid 1px',
        borderColor: grey[300],
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 600,
      },
    },
    MuiButton: {
      contained: {
        '&$disabled': {
          opacity: 0.5,
        },
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  props: {
    MuiFilledInput: {
      disableUnderline: true,
    },
    MuiTextField: {
      variant: 'filled',
    },
    MuiFormControl: {
      variant: 'filled',
    },
    MuiButton: {
      disableElevation: true,
    },
  },
})

export default theme
