import './ActualBalance.scss'

import React, { useContext } from 'react'
import { Typography } from '@material-ui/core'

import ClientContext from '#context/client'
import useLang from 'src/hooks/useLang'
import money from '#utils/money'
import SettingsContext from '#context/settings'

const ActualBalance = () => {
  const clientContext = useContext(ClientContext)
  const { settings } = useContext(SettingsContext)

  const lang = useLang(['sidebar'])

  return (
    <div className="ActualBalance">
      <Typography className="mb-5" variant="caption" color="textSecondary">
        {lang.sidebar.actualBalance}
      </Typography>
      <Typography className="email">
        {money(clientContext.client.partners[0].actual_balance, settings.currency)}
      </Typography>
    </div>
  );
}

export default ActualBalance
