import lang from '#constants/lang'
import store from '../store/store'

const useLang = langFiles => {
  langFiles.forEach(langFile => {
    lang[langFile].setLanguage(store.getState().settings.language)
  })

  return lang
}

export default useLang
