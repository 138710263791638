import './DeliveriesPage.scss'

import {
    Link,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    Checkbox
} from '@material-ui/core'
import React, { Fragment, useEffect, useState, useContext } from 'react'

import { GET_CLIENT_PACKAGES } from '#graphql/queries/order'
import { GET_CLIENT } from '#graphql/queries/client'
import { CREATE_PACKAGE_ID_STICKERS_DOWNLOAD_URL } from '#graphql/mutators/create-package-id-stickers-download-url'

import RoomIcon from '@material-ui/icons/Room'
import classNames from 'classnames'
import useLang from 'src/hooks/useLang'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import PageHeader from '#components/PageHeader/PageHeader'
import LoadingButton from '#components/UI/LoadingButton/LoadingButton'
import SettingsContext from '#context/settings'
import ClientContext from '#context/client'
import money from '#utils/money'

const DeliveriesPage = () => {
    const [deliveries, setDeliveries] = useState([])
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [rowCount, setRowCount] = useState(0)
    const [initLoading, setInitLoading] = useState(true)
    const [selectedPackages, setSelectedPackages] = useState([])
    const [printStickersLoading, setPrintStickersLoading] = useState(false)

    const { settings } = useContext(SettingsContext)
    const { setClient } = useContext(ClientContext)

    const lang = useLang(['deliveries'])

    const [createPackageIdStickersDownloadURL] = useMutation(CREATE_PACKAGE_ID_STICKERS_DOWNLOAD_URL, {
        errorPolicy: 'all',
    })

    const [getClientPackages] = useLazyQuery(GET_CLIENT_PACKAGES, {
        fetchPolicy: 'no-cache',
        variables: {
            first: rowsPerPage,
            page: page + 1,
        },
        onCompleted: data => {
            setInitLoading(false)
            setSelectedPackages([])
            setDeliveries(data.clientPackages.data)
            setRowCount(data.clientPackages.paginatorInfo.total)
        },
    })

    useQuery(GET_CLIENT, {
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            setClient(data.client);
        },
    })

    useEffect(() => {
        getClientPackages()
    }, [])

    const handleSelectPackageChanged = event => {
        const selectedPackageId = event.target.value
        const selectedIndex = selectedPackages.indexOf(selectedPackageId)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedPackages, selectedPackageId)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedPackages.slice(1))
        } else if (selectedIndex === selectedPackages.length - 1) {
            newSelected = newSelected.concat(selectedPackages.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedPackages.slice(0, selectedIndex),
                selectedPackages.slice(selectedIndex + 1),
            )
        }

        setSelectedPackages(newSelected)
    }

    const handleSelectAllPackageChanged = event => {
        let newSelected = []

        if (event.target.checked) {
            newSelected = deliveries.map((delivery) => delivery.package_id)
        }

        setSelectedPackages(newSelected)
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
        getClientPackages()
    }

    const handleChangeRowsPerPage = event => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
        getClientPackages()
    }

    const isSelectedPackage = (package_id) => {
        const isSelected = selectedPackages.indexOf(package_id) !== -1

        return isSelected;
    };

    const deliveriesCount = deliveries.length
    const selectedPackagesCount = selectedPackages.length

    const handlePrintStickers = () => {
        if (!printStickersLoading && selectedPackagesCount > 0) {
            setPrintStickersLoading(true)

            createPackageIdStickersDownloadURL({
                variables: {
                    input: {
                        package_ids: selectedPackages
                    }
                }
            }).then(response => {
                window.open(response.data.createPackageIdStickersDownloadURL.download_url, "_blank")
                setPrintStickersLoading(false)
            })
        }
    }

    return (
        <div className="DeliveriesPage page">
            <PageHeader
                title={lang.deliveries.deliveries}>
                <LoadingButton
                    color="default"
                    disabled={!selectedPackagesCount}
                    loading={printStickersLoading}
                    onClick={handlePrintStickers}>
                    {lang.deliveries.printPackageIdStickers}
                </LoadingButton>
            </PageHeader>

            {!initLoading && (
                <Fragment>
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell></TableCell>
                                    <TableCell padding="checkbox">
                                        <Checkbox
                                            color="primary"
                                            indeterminate={selectedPackagesCount > 0 && selectedPackagesCount < rowsPerPage && selectedPackagesCount !== deliveriesCount}
                                            checked={rowCount > 0 && (selectedPackagesCount === rowsPerPage || selectedPackagesCount === deliveriesCount)}
                                            onChange={handleSelectAllPackageChanged}
                                        />
                                    </TableCell>
                                    <TableCell>{lang.deliveries.track}</TableCell>
                                    <TableCell>{lang.deliveries.packageId}</TableCell>
                                    <TableCell>{lang.deliveries.orderTime}</TableCell>
                                    <TableCell>{lang.deliveries.status}</TableCell>
                                    <TableCell>{lang.deliveries.pickup}</TableCell>
                                    <TableCell>{lang.deliveries.handover}</TableCell>
                                    <TableCell>{lang.deliveries.price}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {deliveries.map(delivery => (
                                    <TableRow key={`delivery-${delivery.package_id}`}>
                                        <TableCell>
                                            <div className={classNames('status-indicator', delivery.public_status)}></div>
                                        </TableCell>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isSelectedPackage(delivery.package_id)}
                                                onChange={handleSelectPackageChanged}
                                                value={delivery.package_id} />
                                        </TableCell>
                                        <TableCell>
                                            <Link className="track" target="_blank" href={delivery.order.tracking_url}>
                                                <RoomIcon />
                                            </Link>
                                        </TableCell>
                                        <TableCell>{delivery.formatted_package_id}</TableCell>
                                        <TableCell>{delivery.order.created_at}</TableCell>
                                        <TableCell>
                                            {lang.deliveries[delivery.public_status]}
                                            {delivery.cancellationReason !== null ?
                                                (
                                                    <>
                                                        <br />
                                                        {delivery.cancellationReason.name}
                                                    </>
                                                ) : ''
                                            }
                                        </TableCell>
                                        <TableCell>{delivery.pick_up.address.formatted_address}</TableCell>
                                        <TableCell>
                                            {delivery.recipients.map((recipient, index) => (
                                                <div key={index}>{recipient.address.formatted_address}</div>
                                            ))}
                                        </TableCell>
                                        <TableCell>
                                            {money(delivery.summary_with_tax, settings.currency)}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        labelRowsPerPage=""
                        component="div"
                        count={rowCount}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                    />
                </Fragment>
            )}
        </div>
    )
}

export default DeliveriesPage
