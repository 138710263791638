import '#assets/css/reset.scss'
import '#assets/css/helpers.scss'
import '#assets/css/colors.scss'
import '#assets/css/overwrite.scss'
import '#assets/css/general.scss'
import '#assets/css/homepage.scss'

import * as settings from '#actions/settings'

import React, { Suspense, useState } from 'react'
import { Route, useHistory } from 'react-router-dom'

import AdminPage from '#pages/AdminPage/AdminPage'
import { AnimatedSwitch } from 'react-router-transition'
import ClientContext from '#context/client'
import Color from 'color'
import { GET_CLIENT } from '#graphql/queries/client'
import { GET_CLIENT_PORTAL_SETTINGS } from '#graphql/queries/client-portal-setting'
import LoginPage from '#pages/LoginPage/LoginPage'
import { MuiThemeProvider } from '@material-ui/core'
import NewPasswordPage from '#pages/NewPasswordPage/NewPasswordPage'
import PasswordResetPage from '#pages/PasswordResetPage/PasswordResetPage'
import PrivateRoute from '#components/HOC/PrivateRoute'
import RegistrationPage from '#pages/RegistrationPage/RegistrationPage'
import SettingsContext from '#context/settings'
import { connect } from 'react-redux'
import muiTheme from '#settings/theme'
import { useQuery } from '@apollo/client'

const App = props => {
  const { onUpdateSettings } = props

  const [client, setClient] = useState({})
  const [settings, setSettings] = useState({})
  const [theme, setTheme] = useState(muiTheme)
  const [initLoading, setInitLoading] = useState(true)

  const history = useHistory()

  useQuery(GET_CLIENT, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setClient(data.client)

      history.push('/deliveries')
    },
  })

  useQuery(GET_CLIENT_PORTAL_SETTINGS, {
    fetchPolicy: 'no-cache',
    onCompleted: data => {
      setSettings(data.client_portal_settings)

      onUpdateSettings(data.client_portal_settings)

      const { browser_title, main_color } = data.client_portal_settings

      // Browser title
      document.title = browser_title

      // Main color
      setTheme(currentTheme => ({
        ...currentTheme,
        palette: {
          ...currentTheme.palette,
          primary: {
            ...currentTheme.palette.primary,
            main: main_color,
            light: Color(main_color).lighten(0.2).hex(),
            dark: Color(main_color).darken(0.2).hex(),
          },
        },
        overrides: {
          ...currentTheme.overrides,
          MuiButton: {
            ...currentTheme.overrides.MuiButton,
            contained: {
              ...currentTheme.overrides.MuiButton.contained,
              '&$disabled': {
                ...currentTheme.overrides.MuiButton.contained['&$disabled'],
              },
            },
          },
        },
      }))

      setInitLoading(false)
    },
  })

  return (
    !initLoading && (
      <div className="App">
        <MuiThemeProvider theme={theme}>
          <ClientContext.Provider value={{ client, setClient }}>
            <SettingsContext.Provider value={{ settings, setSettings }}>
              <Suspense fallback={<div />}>
                <AnimatedSwitch
                  atEnter={{ opacity: 0 }}
                  atLeave={{ opacity: 0 }}
                  atActive={{ opacity: 1 }}
                  className="switch-wrapper"
                >
                  <Route path="/login" component={LoginPage} />
                  <Route path="/registration" component={RegistrationPage} />
                  <Route path="/password-reset" component={PasswordResetPage} />
                  <Route path="/new-password" component={NewPasswordPage} />
                  <PrivateRoute path="/" component={AdminPage} />
                </AnimatedSwitch>
              </Suspense>
            </SettingsContext.Provider>
          </ClientContext.Provider>
        </MuiThemeProvider>
      </div>
    )
  )
}

const mapDispatchToProps = dispatch => {
  return {
    onUpdateSettings: payload => dispatch(settings.update(payload)),
  }
}

export default connect(null, mapDispatchToProps)(App)
